import { IClientOrder } from "../interfaces";

export const statusMappingFilters: {
  all: string[];
  pending: string[];
  completed: string[];
} = {
  all: [
    'pending',
    'cancelled',
    'in-progress',
    'completed',
  ],
  pending: ['pending', 'in-progress'],
  completed: [
    'cancelled',
    'completed',
  ],
};

export const cancelReasonTranslation = {
  "client-cancel-request": "Solicitud Cliente",
  "client-fail-request": "Fallido",
  "no-stock": "No hay stock",
};

const orderCriticFieldsCreate: (keyof IClientOrder)[] = [
  'PointOfSaleId',
  'code',
  'platformCode',
  'customerName',
  'shippingType',
  'LocalId',
  'date',
];

const internalOrderCriticFieldsCreate: (keyof IClientOrder)[] = [
  'PointOfSaleId',
  'code',
  'platformCode',
  'LocalId',
  'date',
  'ClientId',
];

export function isClientOrderValidCreate(clientOrder: Partial<IClientOrder>, isInternalOrder?: boolean) {
  const criticFields = isInternalOrder ? internalOrderCriticFieldsCreate : orderCriticFieldsCreate;
  isClientOrderValid(clientOrder, criticFields);
}

function isClientOrderValid(order: Partial<IClientOrder>, criticFields: (keyof IClientOrder)[]) {
  Object.entries(order).forEach(([key, value]) => {
    if (
      criticFields.includes(key as keyof IClientOrder) &&
      (value === null || value === undefined || value === '')
    ) {
      throw new Error(`Campo ${key} no puede estar vacío.`);
    }
  });
}

export function isEqualToClientOrder(
  newClientOrderData: Partial<IClientOrder>,
  clientOrder: Partial<IClientOrder>
): boolean {
  return Object.entries(newClientOrderData).every(([key, value]) => {
    return value === (clientOrder as IClientOrder)[key as keyof IClientOrder];
  });
}

export function createEmptyClientOrder(localId: number): Partial<IClientOrder> {
  return {
    shippingType: '',
    LocalId: localId,
    date: undefined,
    shippingCost: 0,
    globalDiscount: 0,
    shippingDiscount: 0,
    total: 0,
    OrderDetails: [],
  };
}
