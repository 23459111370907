import axios, { AxiosError, AxiosInstance } from 'axios';
import IEnqueueTaskBody from '../interfaces/IEnqueueTaskBody';
import IGetTaskStatus from '../interfaces/IGetTaskStatus';
import { IManageCredentials } from '../interfaces/IManageCredentials';
import { IInvokeLambda, IOnboardingBody } from '../interfaces';
import { IOrderReprocess } from '../interfaces/IOrderReprocess';

export default class FunctionsApi {
  axios: AxiosInstance;

  constructor(token: string) {
    this.axios = axios.create({
      baseURL: process.env.REACT_APP_FUNCTIONS_URL,
      headers: { Authorization: `Bearer ${token}` },
    });
  }

  async postEnqueueTask(body: IEnqueueTaskBody): Promise<string | null> {
    try {
      const response = await this.axios.post('/enqueue-task', body);
      return response.data.data.task.id;
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(`Error enviando solicitud:${error.response?.data}`);
      }
      return null;
    }
  }

  async getTasksStatus(taskId: string): Promise<IGetTaskStatus> {
    const response = await this.axios.get('/tasks', {
      params: {
        taskId,
      },
    });
    return response.data;
  }

  async createProducts(posId: number): Promise<void> {
    try {
      await this.axios.post('/create-products', {
        posId,
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(`Error enviando solicitud:${error.response?.data}`);
      }
      throw error;
    }
  }

  async manageShopifyWebhook(
    posId: number,
    action: string,
    topic?: string
  ): Promise<string[] | string> {
    try {
      console.log('debug topic', topic);
      const response = await this.axios.post('/manage-webhooks', {
        posId,
        topic,
        action,
      });
      return response.data.body;
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(
          `No se pudo enviar la solicitud: ${error.response?.data.message}`
        );
      }
      throw new Error('Ocurrió un error desconocido');
    }
  }

  async manageCredentials(data: IManageCredentials): Promise<any> {
    try {
      const response = await this.axios.post('/manage-credentials', data);
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(
          `No se pudo enviar la solicitud: ${error.response?.data.message}`
        );
      }
      throw new Error('Ocurrió un error desconocido');
    }
  }

  async getSignedUrl(key: string, contentType: string): Promise<string> {
    try {
      const response = await this.axios.get(
        `/signed-url?key=${key}&contentType=${contentType}`
      );
      return response.data.signedUrl;
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(
          `No se pudo enviar la solicitud: ${error.response?.data.message}`
        );
      }
      throw new Error('Ocurrió un error desconocido');
    }
  }

  async onboarding(data: IOnboardingBody): Promise<void> {
    try {
      await this.axios.post('/onboarding', data);
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(
          `No se pudo enviar la solicitud: ${error.response?.data.message}`
        );
      }
      throw new Error('Ocurrió un error desconocido');
    }
  }

  async invokeLambda(data: IInvokeLambda): Promise<void> {
    try {
      await this.axios.post('/invoke-lambda', data);
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(
          `No se pudo enviar la solicitud: ${error.response?.data.message}`
        );
      }
      throw new Error('Ocurrió un error desconocido');
    }
  }

  async orderReprocess(data: IOrderReprocess): Promise<void> {
    try {
      await this.axios.post('/order-reprocess', data);
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(
          `No se pudo enviar la solicitud: ${error.response?.data.message}`
        );
      }
      throw new Error('Ocurrió un error desconocido');
    }
  }
}
