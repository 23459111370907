import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';

const CreateOrderButton = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate('new');
  };

  return (
    <div>
      <Button
        variant="text"
        color="primary"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <AddIcon fontSize="small" sx={{ marginRight: 0.2 }} />
        Crear Pedido
      </Button>
    </div>
  );
};

export default CreateOrderButton;
