import { Paper } from '@mui/material';
import { ChangeEvent, Dispatch } from 'react';
import {
  ICustomChangeEvent,
  ILocal,
  IOrder,
  IAddress,
} from '../../interfaces';
import { capitalize } from '../../lib/utils';
import CustomInputField from './CustomInputField';
import { incidenceReasonTranslation } from '../../lib/orders';

const orderInputKeys: { [key: string]: keyof (IOrder & IAddress) } = {
  TipoInput: 'shippingType',
  PrioridadInput: 'pickingPriority',
  CourierInput: 'CourierId',
};

interface OrderDataProps {
  order: Partial<IOrder>;
  setOrderData: (orderData: Partial<IOrder>) => void;
  editMode: boolean;
  children?: JSX.Element | JSX.Element[];
  onInputChange?: (
    event: ChangeEvent<HTMLInputElement> | ICustomChangeEvent
  ) => void;
  globalDisabled?: boolean;
  destinationLocal?: ILocal | null;
  setDestinationLocal?: Dispatch<React.SetStateAction<ILocal | null>>;
}

const OrderData = (props: OrderDataProps) => {
  const {
    order,
    setOrderData,
    editMode,
    children,
    onInputChange = () => undefined,
    globalDisabled = false,
  } = props;
  const lastShipment = order.Shipments?.length ? order.Shipments[0] : null;

  async function handleInputChange(
    event: ChangeEvent<HTMLInputElement> | ICustomChangeEvent
  ) {
    const dataToSet = {
      [orderInputKeys[event.target.id]]: event.target.value || null,
    };
    setOrderData(dataToSet);
    // trigger event
    onInputChange(event);
  }

  return (
    <div id="OrderData">
      <Paper className="Paper" elevation={2}>
          <h2>{order.internalCode || order.id}</h2>
        <CustomInputField
          disabled
          label="MFC"
          value={capitalize(order.Local?.name || '')}
          editMode={false}
          onChange={handleInputChange}
        />
        <>
          <CustomInputField
            disabled
            label="Estado Orden"
            value={order.status}
            editMode={false}
            onChange={handleInputChange}
            upperCase
          />
          <CustomInputField
            disabled
            label="Estado Envío"
            value={order.activeShipment?.status || (order.Shipments?.length ? order.Shipments[0].status : '')}
            editMode={false}
            onChange={handleInputChange}
            upperCase
          />
          {order.incidenceReason && (
            <CustomInputField
              disabled
              label="Incidencia"
              value={incidenceReasonTranslation[order.incidenceReason]}
              editMode={false}
              onChange={handleInputChange}
            />
          )}
          </>
        <CustomInputField
          inputType="checkbox"
          label="Prioridad"
          value={String(order.pickingPriority)}
          editMode={editMode}
          onChange={handleInputChange}
          disabled={globalDisabled}
        />
          <>
            <h3>Envío</h3>
            <CustomInputField
              disabled
              label="Tipo"
              value={order.shippingType || ''}
              editMode={editMode}
              onChange={handleInputChange}
            />
            <CustomInputField
              disabled
              label="Courier"
              editMode={editMode}
              onChange={handleInputChange}
              value={lastShipment?.Courier?.name || ''}
            />
          </>
        {children}
      </Paper>
    </div>
  );
};

export default OrderData;
