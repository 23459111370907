import * as React from 'react';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import { ILocal, IClientOrder, ITransferClientOrder } from '../interfaces';
import { capitalize } from '../lib/utils';


interface HeadCell<T> {
  disablePadding: boolean;
  id: keyof T;
  label: string;
  type: 'string' | 'date' | 'number' | 'function';
  render?: (data: T) => string;
}

export const headCells: readonly HeadCell<IClientOrder>[] = [
  {
    id: 'id',
    type: 'number',
    disablePadding: false,
    label: 'Id',
  },
  {
    id: 'code',
    type: 'string',
    disablePadding: false,
    label: 'Código',
  },
  {
    id: 'Client',
    type: 'function',
    disablePadding: false,
    label: 'Marca',
    render: (x) => `${x.Client?.name || ''}`,
  },
  {
    id: 'customerName',
    type: 'string',
    disablePadding: false,
    label: 'Nombre Cliente',
  },
  {
    id: 'status',
    type: 'string',
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'date',
    type: 'date',
    disablePadding: false,
    label: 'Fecha',
  },
  {
    id: 'shippingType',
    type: 'string',
    disablePadding: false,
    label: 'Envío',
  },
];

export const transferHeadCells: readonly HeadCell<ITransferClientOrder>[] = [
  {
    id: 'id',
    type: 'number',
    disablePadding: false,
    label: 'Id',
  },
  {
    id: 'code',
    type: 'string',
    disablePadding: false,
    label: 'code',
  },
  {
    id: 'Client',
    type: 'function',
    disablePadding: false,
    label: 'Client',
    render: (x) => `${x.Client?.name || ''}`,
  },
  {
    id: 'status',
    type: 'string',
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'date',
    type: 'date',
    disablePadding: false,
    label: 'Fecha',
  },
  {
    id: 'OrderReceptions',
    type: 'function',
    disablePadding: false,
    label: 'Reception',
    render: (x) => `${x.OrderReceptions[0]?.Reception?.id} - ${x.OrderReceptions[0]?.Reception?.status}`,
  },
];

interface ClientOrdersTableHeadProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof IClientOrder | keyof ITransferClientOrder,
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  sort: {[key: string]: string;}[];
  rowCount: number;
  showOnlyTransferOrders: boolean;
}

export function ClientOrdersTableHead(props: ClientOrdersTableHeadProps) {
  const { onSelectAllClick, sort, numSelected, rowCount, showOnlyTransferOrders, onRequestSort } =
    props;
  const createSortHandler =
    (property: keyof IClientOrder | keyof ITransferClientOrder) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };
    const entityHeadCells = showOnlyTransferOrders ? transferHeadCells : headCells;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {entityHeadCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.type === 'number' ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={sort[0][headCell.id] ? sort[0][headCell.id] as "desc" | "asc" : false}
            >
              <TableSortLabel
                active={sort[0][headCell.id] ? true : false}
                direction={(sort[0][headCell.id] ? sort[0][headCell.id] : 'asc') as "desc" | "asc"}
                onClick={createSortHandler(headCell.id)}
                disabled={'OrderReceptions' === headCell.id || 'Client' === headCell.id}
              >
                {headCell.label}
                {sort[0][headCell.id] ? (
                  <Box component="span" sx={visuallyHidden}>
                    {sort[0][headCell.id] === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
      </TableRow>
    </TableHead>
  );
}

interface ClientOrdersTableToolbarProps {
  numSelected: number;
  local: ILocal;
  children?: JSX.Element | JSX.Element[];
}

export const ClientOrdersTableToolbar = (props: ClientOrdersTableToolbarProps) => {
  const { numSelected, local, children } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      <div className="OrdersTableToolbar">
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: '1 1 100%' }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <div className="Left">
            <Typography
              // sx={{ flex: '1 1 100%' }}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              Pedidos de {capitalize(local.name)}
            </Typography>
            {children}
          </div>
        )}
        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Filter list">
            <IconButton>
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </Toolbar>
  );
};
