import { IModel } from '../../interfaces';

export const products: IModel = {
  name: 'Products',
  path: 'api/products',
  attributes: [
    {
      name: 'sku',
      type: 'string',
      required: true,
    },
    {
      name: 'name',
      type: 'string',
      required: true,
    },
    {
      name: 'ClientId',
      type: 'number',
      required: true,
    },
    {
      name: 'SizeId',
      type: 'number',
      required: true,
    },
    {
      name: 'isService',
      type: 'boolean',
      required: false,
    },
    {
      name: 'hasModifiers',
      type: 'boolean',
      required: false,
    },
    {
      name: 'pvp',
      type: 'number',
      required: false,
    },
    {
      name: 'safetyStock',
      type: 'number',
      required: false,
    },
    {
      name: 'barcode',
      type: 'string',
      required: false,
    },
    {
      name: 'isPack',
      type: 'boolean',
      required: false,
    },
    {
      name: 'isWeighted',
      type: 'boolean',
      required: false,
    },
    {
      name: 'isFamily',
      type: 'boolean',
      required: false,
    },
    {
      name: 'familySku',
      type: 'string',
      required: false,
    },
    {
      name: 'needsLotTracking',
      type: 'boolean',
      required: false,
    },
    {
      name: 'storageType',
      type: 'string',
      required: false,
    },
    {
      name: 'ageVerification',
      type: 'boolean',
      required: false,
    },
    {
      name: 'expirationAlert',
      type: 'number',
      required: false,
    },
    {
      name: 'activated',
      type: 'boolean',
      required: false,
    },
    {
      name: 'weight',
      type: 'number',
      required: false,
    },
    {
      name: 'length',
      type: 'number',
      required: false,
    },
    {
      name: 'height',
      type: 'number',
      required: false,
    },
    {
      name: 'depth',
      type: 'number',
      required: false,
    }
  ],
};
