import { SetStateAction, useContext, useEffect, useState } from 'react';
import Papa from 'papaparse';
import {
  IModel,
  IOrder,
  IOrderBackorders,
  IOrderDetail,
  IOrderDetailCreate,
  IProduct,
} from '../../interfaces';
import OrderDetail from './OrderDetail';
import ProductSearch from './ProductSearch';
import { GlobalContext } from '../../store';
import React from 'react';
import OrderDetailNote from './OrderDetailNote';
import SwitchButton from '../SwitchButton';
import { Button } from '@mui/material';
import DropFile from '../DropFile';
import { CsvUploader } from '../../lib/csvUploader';
import { orders, transferWithdrawals } from '../../lib/models';
import AlertDialog from '../AlertDialog';
import generateKey from '../../helpers/generateKey';

interface OrderDetailsProps {
  order: Partial<IOrder>;
  backorders?: IOrderBackorders;
  isOnHold?: boolean;
  children?: JSX.Element | JSX.Element[];
  pointOfSaleId: number | null;
  onUpdateOrderDetail: (
    key: string,
    id: string | number | symbol,
    value: number | string
  ) => void;
  onDeleteOrderDetail: (item: IOrderDetail) => void;
  onAddOrderDetail: (item: IOrderDetailCreate) => void;
  editMode?: boolean;
  globalDisabled?: boolean;
  isInternalOrder?: boolean;
  handleAddCsvOrderDetails?: (
    data: IOrderDetail[],
    pointOfSaleId: number
  ) => void;
  resetOrderDetails?: () => void;
}

const OrderDetails = ({
  order,
  isOnHold,
  backorders,
  children,
  pointOfSaleId,
  onAddOrderDetail,
  onUpdateOrderDetail,
  onDeleteOrderDetail,
  globalDisabled = false,
  editMode = false,
  isInternalOrder = false,
  handleAddCsvOrderDetails,
  resetOrderDetails,
}: OrderDetailsProps) => {
  const orderDetails = order.OrderDetails || [];
  const { api } = useContext(GlobalContext);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [selectedOrderDetail, setSelectedOrderDetail] =
    React.useState<IOrderDetail | null>(null);
  const [loadProductsOneByOne, setLoadProductsOneByOne] =
    React.useState<boolean>(false);
  const [csv, setCsv] = useState<File | null>(null);
  const [csvSubmitted, setCsvSubmitted] = useState<boolean>(false);
  const [openModalAlert, setOpenModalAlert] = useState<boolean>(false);
  const [modalAlertTitle, setModalAlertTitle] = useState<string>('');
  const [modalAlertText, setModalAlertText] = useState<string>('');
  const [rows, setRows] = useState<IOrderDetail[]>([]);

  function handleItemChange(
    key: string,
    id: string | number | symbol,
    value: number | string
  ) {
    onUpdateOrderDetail(key, id, value);
  }

  function handleNoteModal(id: number) {
    const orderDetail = orderDetails.find((row) => row.id === id);
    if (orderDetail) {
      setSelectedOrderDetail(orderDetail);
      setModalOpen(true);
    }
  }

  async function handleAddItem(newValue: IProduct, packId?: number) {
    const orderDetail: IOrderDetailCreate = {
      ProductId: newValue.id,
      amount: 1,
      price: null,
      discount: 0,
      note: null,
      isPack: newValue.isPack,
      ReactComponentId: generateKey(newValue.id),
    };
    if (order.id) {
      // this happens when user is adding order detail to an existing order
      orderDetail.OrderId = order.id;
    }
    if (orderDetail.isPack) {
      orderDetail.PackComponents = await api.getPackComponents(
        orderDetail.ProductId
      );
    }
    if (packId) {
      orderDetail.PackParentId = packId;
      orderDetail.price = 0;
    }
    onAddOrderDetail(orderDetail);
  }

  function getPackChildren(packParentId: number | undefined) {
    if (packParentId) {
      return orderDetails.filter(
        (orderDetail) => orderDetail.PackParentId === packParentId
      );
    }
  }

  function downloadCsvTemplate() {
    let selectedModel: IModel = orders;
    if (isInternalOrder) {
      selectedModel = transferWithdrawals;
    }
    const csvHeaders = selectedModel.attributes.map(
      (attribute) => attribute.name
    );
    const csv = Papa.unparse({
      fields: csvHeaders,
      data: [],
    });
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', `${selectedModel?.name}.csv`);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  function handleModal(title: string, text: string) {
    setOpenModalAlert(true);
    setModalAlertTitle(title);
    setModalAlertText(text);
  }

  // Delete previous orderDetails
  useEffect(() => {
    if (resetOrderDetails) {
      resetOrderDetails();
    }
  }, [loadProductsOneByOne]);

  useEffect(() => {
    if (handleAddCsvOrderDetails && pointOfSaleId) {
      handleAddCsvOrderDetails(rows, pointOfSaleId);
    }
  }, [rows]);

  useEffect(() => {
    (async () => {
      if (csv) {
        const selectedModel: IModel = isInternalOrder ? transferWithdrawals : orders;
        const uploader = new CsvUploader(csv, selectedModel);
        await uploader.validate(
          handleModal,
          setRows as unknown as React.Dispatch<
            SetStateAction<{ [key: string]: string }[]>
          >,
          'create'
        );
      }
    })();
  }, [csv]);

  return (
    <>
      <AlertDialog
        title={modalAlertTitle}
        text={modalAlertText}
        open={openModalAlert}
        setOpen={setOpenModalAlert}
      />
      <OrderDetailNote
        open={modalOpen}
        setOpen={setModalOpen}
        orderDetail={selectedOrderDetail}
        onChange={handleItemChange}
      />
      <div id="OrderDetails">
        {editMode ? (
          <>
            <h2>Cargar Productos</h2>
            <div className="ItemsWrapper">
              <h3>Cargar csv</h3>
              <SwitchButton
                switchButton={loadProductsOneByOne}
                handleSwitchButton={() => {
                  if (!globalDisabled) {
                    setLoadProductsOneByOne(!loadProductsOneByOne);
                  }
                }}
              />
            </div>
          </>
        ) : (
          <h2>Productos</h2>
        )}
        {!loadProductsOneByOne ? (
          <>
            {editMode && (
              <div className="Row Between">
                <h3>Productos</h3>
                <ProductSearch
                  actualProductIds={orderDetails
                    .filter((orderDetail) => !orderDetail.PackParentId)
                    .map((x) => x.ProductId)}
                  pointOfSaleId={pointOfSaleId}
                  onAddItem={handleAddItem}
                  disabled={globalDisabled}
                />
              </div>
            )}
            {orderDetails.length === 0 && (
              <div className="Row Start">
                <p className="Placeholder">
                  Comienza agregando productos desde el buscador...
                </p>
              </div>
            )}
          </>
        ) : (
          editMode && (
            <>
              <div className="MarginTop">
                <DropFile
                  changeHandler={setCsv}
                  createItemsSubmitted={csvSubmitted}
                  setCreateItemsSubmitted={setCsvSubmitted}
                />
              </div>
              <div className="MarginTop">
                <Button variant="contained" onClick={downloadCsvTemplate}>
                  Descargar plantilla de ejemplo
                </Button>
              </div>
            </>
          )
        )}
        {orderDetails && (
          <div className="Row" id="OrderDetailsHeader">
            <div className="Flex2">Cantidad</div>
            {isOnHold && <div className="Flex2">Faltantes</div>}
            <div className="Flex6">Producto</div>
            {!isInternalOrder && (
              <>
                <div className="Flex2">Precio</div>
                <div className="Flex2">Descuento</div>
                <div className="Flex2">Subtotal</div>
              </>
            )}
            <div className="Flex1"></div>
          </div>
        )}
        {orderDetails
          .filter((orderDetail) => !orderDetail.PackParentId)
          .map((item, idx) => (
            <OrderDetail
              key={idx}
              idx={item.ReactComponentId}
              pointOfSaleId={pointOfSaleId}
              isOnHold={isOnHold}
              backorders={backorders}
              item={item}
              packs={orderDetails.filter((orderDetail) => orderDetail.isPack)}
              onChange={handleItemChange}
              onDelete={onDeleteOrderDetail}
              handlePackChildren={getPackChildren}
              onAddPackChildren={handleAddItem}
              onNote={handleNoteModal}
              editMode={editMode}
              disabled={globalDisabled}
              showPrice={!isInternalOrder}
            />
          ))}
        {children}
      </div>
    </>
  );
};

export default OrderDetails;
