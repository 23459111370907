import {
  BooleanInput,
  DateField,
  Edit,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
} from 'react-admin';
import { transformInput } from '../../../lib/utils';

export const ProductEdit = () => (
  <Edit transform={transformInput}>
    <SimpleShowLayout>
      <TextField source="id" />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </SimpleShowLayout>
    <SimpleForm>
      <TextInput source="sku" />
      <TextInput source="rawSku" />
      <TextInput source="name" />
      <ReferenceInput source="ClientId" reference="clients" perPage={10000}>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <BooleanInput source="isService" />
      <BooleanInput source="hasModifiers" />
      <NumberInput source="pvp" />
      <NumberInput source="safetyStock" />
      <NumberInput source="expirationAlert" />
      <TextInput source="barcode" />
      <BooleanInput source="isPack" />
      <BooleanInput source="isWeighted" />
      <BooleanInput source="needsLotTracking" />
      <BooleanInput source="ageVerification" />
      <BooleanInput source="isFamily" />
      <TextInput source="familySku" />
      <SelectInput
        source="storageType"
        choices={[
          { id: 'dry', name: 'Dry' },
          { id: 'air-conditioned', name: 'Air Conditioned' },
          { id: 'refrigerated', name: 'Refrigerated' },
          { id: 'frozen', name: 'Frozen' },
        ]}
      />
      <BooleanInput source="activated" />
      <NumberInput source="weight" />
      <NumberInput source="length" />
      <NumberInput source="depth" />
      <NumberInput source="height" />
      <NumberInput source="unitsPerBox" />
      <NumberInput source="boxesPerPallet" />
    </SimpleForm>
  </Edit>
);
