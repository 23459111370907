import {
  ArrayField,
  Datagrid,
  DateField,
  Edit,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
} from 'react-admin';
import { transformInput } from '../../../lib/utils';

export const OrderEdit = () => {

  return (
    <Edit transform={transformInput}>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="internalCode" />
        <TextField source="ClientOrderId" />
        <DateField source="createdAt" showTime />
        <DateField source="updatedAt" showTime />
      </SimpleShowLayout>
      <SimpleForm>
        <TextInput source="status" />
        <TextInput source="incidenceReason" />
        <NumberInput source="chargeRatePercentage" />
        <TextInput source="pat" />
        <TextInput source="pickingPromise" />
        <ArrayField source="notFoundProducts">
          <Datagrid>
            <TextField source="name" />
            <TextField source="sku" />
            <TextField source="price" />
            <TextField source="amount" />
            <TextField source="discount" />
          </Datagrid>
        </ArrayField>
        <TextInput source="shippingType" />
        <ReferenceInput source="LocalId" reference="locals" perPage={10000} filter={{ active: true }}>
          <SelectInput optionText="name" />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
};
