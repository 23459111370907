import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useState } from 'react';
import { statusMappingFilters } from '../../lib/clientOrders';

interface IStatusToggleButtonsProps {
  onChange: (newValue: string[]) => void;
}

export default function StatusToggleButtons({
  onChange,
}: IStatusToggleButtonsProps) {
  const [value, setValue] = useState<string>('all');

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newValue: 'all' | 'pending' | 'completed'
  ) => {
    setValue(newValue);
    onChange(statusMappingFilters[newValue]);
  };

  return (
    <ToggleButtonGroup
      color="primary"
      value={value}
      exclusive
      onChange={handleChange}
      aria-label="Platform"
    >
      <ToggleButton value="all">Todos</ToggleButton>
      <ToggleButton value="pending">Pendientes</ToggleButton>
      <ToggleButton value="completed">Completados</ToggleButton>
    </ToggleButtonGroup>
  );
}
