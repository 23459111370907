import axios, { AxiosError, AxiosInstance } from 'axios';
import {
  IClient,
  ICommune,
  IIntegration,
  ILocal,
  IOrder,
  IOrderBackorders,
  IOrderDetail,
  IOrderDetailCreate,
  IPointOfSale,
  IProduct,
  IUser,
  IExtendedLoss,
  IProductLocation,
  IProductLocationGraphqlObject,
  IShipmentReceiverInfo,
  ICourier,
  IAddress,
  IFetchProductLocationsOptions,
  IProductWithStock,
  IPointOfSaleProductWithProduct,
  ICountry,
  IVTEXWarehouse,
  IVTEXSalesChannel,
  IPointOfSaleLocal,
  IApiResourceOptions,
  IOrderType,
  ILoss,
  IClientOrder,
  ITransferClientOrder,
  IClientOrderExtendedTransferClientOrder,
} from '../interfaces';
import * as queries from './graphql/queries';
import * as variables from './graphql/variables';
import * as sorting from './graphql/sorting';
import { IClientLocals } from '../interfaces/IClientLocals';
import { IPickingError } from '../interfaces/IPickingError';

interface GetOptions {
  filter?: { [key: string]: string | number | boolean | string[] | number[] };
  rowsPerPage?: number;
  page?: number;
}

interface GetLocalOptions extends GetOptions {
  sort?: { order: 'asc'; by: 'name' };
}

interface GenerateMoveOptions {
  ProductLocationId: number;
  quantity: number;
  type: string;
  reason: string;
  comment: string;
}

export default class Api {
  axios: AxiosInstance;
  graphql: AxiosInstance;

  constructor(token: string) {
    this.axios = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}`,
      headers: { Authorization: `Bearer ${token}` },
    });
    this.graphql = axios.create({
      baseURL: process.env.REACT_APP_GRAPHQL_URL,
      headers: { Authorization: `Bearer ${token}` },
    });
  }

  async getUser(): Promise<IUser | string> {
    try {
      const response = await this.axios.get<IUser>('/api/account');
      const user = response.data;
      return user;
    } catch (error) {
      if (error instanceof AxiosError) {
        return error.response?.data.error as string;
      }
      return 'Unexpected error';
    }
  }

  async createUser(user: Partial<IUser>): Promise<IUser> {
    try {
      const response = await this.axios.post('/api/users', user);
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(
          `Error creando usuario:<br>${error.response?.data?.message}`
        );
      }
      throw error;
    }
  }

  async getLocals({
    filter = {},
    rowsPerPage = 100,
    page = 0,
    sort = { order: 'asc', by: 'name' },
  }: GetLocalOptions = {}): Promise<ILocal[]> {
    const start = page * rowsPerPage;
    const end = (page + 1) * rowsPerPage;
    const rangeParam = JSON.stringify([start, end]);
    const localFilters = JSON.stringify(filter);
    const sortParam = JSON.stringify([sort.by, sort.order]);
    const response = await this.axios.get('/api/locals', {
      params: {
        filter: localFilters,
        range: rangeParam,
        sort: sortParam,
      },
    });
    return response.data;
  }

  async getLocalById(localId: number | string): Promise<ILocal> {
    try {
      const response = await this.axios.get<ILocal>(`/api/locals/${localId}`);
      const local = response.data;
      return local;
    } catch (e) {
      throw new Error(
        `Error getting local with ID: ${localId}. It was not found.`
      );
    }
  }

  async getOrderTypeById(orderTypeId?: number): Promise<IOrderType> {
    const response = await this.axios.get(`/api/order-types/${orderTypeId}`);
    const orderType: IOrderType = response.data;
    return orderType;
  }

  async getOrders({
    searchValue,
    filter = {},
    sort = [{ id: 'desc' }],
    rowsPerPage = 10,
    page = 0,
  }: IApiResourceOptions): Promise<{ orders: IOrder[]; count: number }> {
    try {
      const query = queries.getOrders(rowsPerPage, rowsPerPage * page);
      const response = await this.graphql.post('/graphql', {
        query,
        variables: {
          ...variables.getOrdersVariables({ sort, filter, searchValue }),
        },
      });
      if ('errors' in response.data) {
        if (response.data.errors[0].message.includes('Invalid response')) {
          throw new Error('Token expired');
        }
        throw new Error(response.data.errors[0].message);
      }
      const orders = response.data.data.Orders;
      const count = response.data.data.Orders_aggregate.aggregate.total;
      return { orders, count };
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.data.message);
      }
      throw new Error('Unexpected error');
    }
  }

  async getClientOrders({
    searchValue,
    filter = {},
    sort = [{ id: 'desc' }],
    rowsPerPage = 10,
    page = 0,
  }: IApiResourceOptions): Promise<{ clientOrders: IClientOrder[]; count: number }> {
    try {
      const query = queries.getClientOrders(rowsPerPage, rowsPerPage * page);
      const response = await this.graphql.post('/graphql', {
        query,
        variables: {
          ...variables.getClientOrdersVariables({ sort, filter, searchValue }),
        },
      });
      if ('errors' in response.data) {
        if (response.data.errors[0].message.includes('Invalid response')) {
          throw new Error('Token expired');
        }
        throw new Error(response.data.errors[0].message);
      }
      const clientOrders = response.data.data.ClientOrders;
      const count = response.data.data.ClientOrders_aggregate.aggregate.total;
      return { clientOrders, count };
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.data.message);
      }
      throw new Error('Unexpected error');
    }
  }

  async getOrderTypes({
    filter = {},
    rowsPerPage = 100,
    page = 0,
  }: IApiResourceOptions): Promise<IOrderType[]> {
    const query = queries.getOrderTypes(rowsPerPage, rowsPerPage * page);
    const response = await this.graphql.post('/graphql', {
      query,
      variables: {
        ...variables.getOrderTypesVariables({ filter }),
      },
    });
    if ('errors' in response.data) {
      if (response.data.errors[0].message.includes('Invalid response')) {
        throw new Error('Token expired');
      }
      throw new Error(response.data.errors[0].message);
    }
    const orderTypes = response.data.data.OrderTypes;
    return orderTypes;
  }
  
  async getLosses({
    filter = {},
    page = 0,
    rowsPerPage = 1000,
    sort = [],
  }: IApiResourceOptions): Promise<{
    losses: IExtendedLoss[];
    count: number;
  }> {
    try {
      const query = queries.getLocalLosses(rowsPerPage, rowsPerPage * page);
      const response = await this.graphql.post('/graphql', {
        query,
        variables: {
          ...variables.getLocalLosses({
            filter,
            sort,
          }),
        },
      });
      if ('errors' in response.data) {
        if (response.data.errors[0].message.includes('Invalid response')) {
          throw new Error('Token expired');
        }
        throw new Error(response.data.errors[0].message);
      }
      const losses = response.data.data.Losses.map((loss : ILoss) => {
        const {
          id,
          status,
          createdAt,
          Withdrawal,
          ProductLocationMove: {
            id: productLocationMoveId,
            ProductId,
            LotId,
            ClientId,
            quantity,
            reason,
            Location,
            Product,
            Lot
          }
        } = loss;
      
        return {
          id,
          status,
          createdAt,
          Withdrawal,
          productLocationMoveId,
          ProductId,
          LotId,
          ClientId,
          quantity,
          reason,
          Location,
          Product,
          Lot
        };
      });
      const count = response.data.data.Losses_aggregate.aggregate.total;
      return { losses, count };
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.data.message);
      }
      throw new Error('Unexpected error');
    }
  }

  async makeWithdrawal({
    losses,
    type,
  }: {
    losses: readonly number[];
    type: string;
  }) {
    try {
      await this.axios.post('/api/withdrawals/losses', {
        Losses: losses,
        type,
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(
          `Error actualizando solicitud:<br>${error.response?.data?.message}`
        );
      }
    }
  }

  async changeLossesStatus({
    losses,
    status,
  }: {
    losses: readonly number[];
    status: string;
  }) {
    try {
      await Promise.all(
        losses.map((lossId) =>
          this.axios.put(`/api/losses/${lossId}`, {
            status,
          })
        )
      );
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(
          `Error actualizando solicitud:<br>${error.response?.data?.message}`
        );
      }
    }
  }

  async generateMove({
    ProductLocationId,
    quantity,
    type,
    reason,
    comment,
  }: GenerateMoveOptions) {
    try {
      await this.axios.post(
        `/api/product-locations/${ProductLocationId}/generate-move`,
        {
          quantity,
          type,
          reason,
          comment,
        }
      );
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(
          `Error enviando solicitud:<br>${error.response?.data?.message}`
        );
      }
    }
  }

  async getOrderBackorders(orderId: number): Promise<IOrderBackorders | null> {
    const response = await this.axios.get(`/api/orders/${orderId}/backorders`);
    const backorders: IOrderBackorders = response.data;
    return backorders;
  }

  async getPackComponents(productId: number): Promise<IProduct[]> {
    const response = await this.axios.get(
      `/api/products/${productId}/components`
    );
    const products: IProduct[] = response.data;
    return products;
  }

  async updateClientOrder(clientOrderId: number, fields: any) {
    try {
      await this.axios.put(`/api/client-orders/${clientOrderId}`, fields);
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(
          `Error enviando solicitud:<br>${error.response?.data?.message}`
        );
      }
    }
  }

  async updateOrder(orderId: number, fields: any) {
    try {
      await this.axios.put(`/api/orders/${orderId}`, fields);
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(
          `Error enviando solicitud:<br>${error.response?.data?.message}`
        );
      }
    }
  }

  async createClientOrder(clientOrder: Partial<IClientOrder>): Promise<number> {
    try {
      const response = await this.axios.post('/api/client-orders', clientOrder);
      const clientOrderId: number = response.data.id;
      return clientOrderId;
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(
          `Error enviando solicitud:<br>${error.response?.data?.message}`
        );
      }
    }
    return 0;
  }

  async cancelClientOrder(clientOrderId: number, cancelReason: string): Promise<void> {
    try {
      await this.axios.post(`/api/client-orders/${clientOrderId}/cancel`, {
        cancelReason,
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(
          `Error enviando solicitud:<br>${error.response?.data?.message}`
        );
      }
    }
  }

  async cancelOrder(orderId: number): Promise<void> {
    try {
      await this.axios.post(`/api/orders/${orderId}/cancel`);
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(
          `Error enviando solicitud:<br>${error.response?.data?.message}`
        );
      }
    }
  }

  async generateIncidence(
    orderId: number,
    incidenceReason?: string,
    options?: {
      compromisedType?: string;
      wantsDuplicate?: boolean;
      pickingErrorData?: IPickingError[];
    }): Promise<void> {
    try {
      await this.axios.post(`/api/orders/${orderId}/generate-incidence`, {
        incidenceReason: incidenceReason || null,
        options,
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(
          `Error enviando solicitud:<br>${error.response?.data?.message}`
        );
      }
    }
  }

  async updateShipment(
    shipmentId: number,
    data: { status?: string },
  ): Promise<void> {
    try {
      await this.axios.put(`/api/shipments/${shipmentId}`, data);
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(
          `Error enviando solicitud:<br>${error.response?.data?.message}`
        );
      }
    }
  }

  async reshipOrder(
    orderId: number,
    data: { courierId?: number; shippingType?: string; newAddress?: IAddress } = {}
  ): Promise<void> {
    try {
      await this.axios.post(`/api/orders/${orderId}/reship`, data);
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(
          `Error enviando solicitud:<br>${error.response?.data?.message}`
        );
      }
    }
  }

  async reallocateOrder(
    orderId: number,
    data: { localId?: number } = {}
  ): Promise<void> {
    try {
      await this.axios.post(`/api/orders/${orderId}/reallocate`, data);
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(
          `Error enviando solicitud:<br>${error.response?.data?.message}`
        );
      }
    }
  }

  async getConnectionTypes(
    sort = { order: 'asc', by: 'name' }
  ): Promise<any[]> {
    const sortParam = JSON.stringify([sort.by, sort.order]);
    const response = await this.axios.get('/api/connection-types', {
      params: {
        sort: sortParam,
        range: '[0, 500]',
      },
    });
    const connectionTypes: any[] = response.data;
    return connectionTypes;
  }

  async getRoles(sort = { order: 'asc', by: 'name' }): Promise<any[]> {
    const sortParam = JSON.stringify([sort.by, sort.order]);
    const response = await this.axios.get('/api/roles', {
      params: {
        sort: sortParam,
        range: '[0, 500]',
      },
    });
    const roles: any[] = response.data;
    return roles;
  }

  async getClients(
    filter = {},
    sort = { order: 'asc', by: 'name' }
  ): Promise<IClient[]> {
    const sortParam = JSON.stringify([sort.by, sort.order]);
    const response = await this.axios.get('/api/clients', {
      params: {
        sort: sortParam,
        range: '[0, 500]',
        filter: JSON.stringify(filter),
      },
    });
    const clients: IClient[] = response.data;
    return clients;
  }

  async getClientLocals(ClientId?: number): Promise<IClientLocals[]> {
    const query = queries.getClientLocals();
    const response = await this.graphql.post('/graphql', {
      query,
      variables: {
        ...variables.getClientLocals({
          ClientId,
        }),
      },
    });
    if ('errors' in response.data) {
      if (response.data.errors[0].message.includes('Invalid response')) {
        throw new Error('Token expired');
      }
      throw new Error(response.data.errors[0].message);
    }
    return response.data.data.ClientLocals;
  }

  async getClientById(clientId?: number): Promise<IClient> {
    const response = await this.axios.get(`/api/clients/${clientId}`);
    const client: IClient = response.data;
    return client;
  }

  async getInactiveClients(): Promise<IClient[]> {
    const query = queries.getInactiveClients();
    const response = await this.graphql.post('/graphql', {
      query,
    });
    if ('errors' in response.data) {
      if (response.data.errors[0].message.includes('Invalid response')) {
        throw new Error('Token expired');
      }
      throw new Error(response.data.errors[0].message);
    }
    return response.data.data.Clients;
  }

  async getIntegrations(clientId?: number): Promise<IIntegration[]> {
    let response;
    if (clientId) {
      response = await this.axios.get(`/api/clients/${clientId}/integrations`, {
        params: { filter: JSON.stringify({ ClientId: clientId }) },
      });
    } else {
      response = await this.axios.get(`/api/integrations`);
    }
    const integrations: IIntegration[] = response.data;
    return integrations;
  }

  async getCountries(): Promise<ICountry[]> {
    const query = queries.getCountries();
    const response = await this.graphql.post('/graphql', {
      query,
    });
    if ('errors' in response.data) {
      if (response.data.errors[0].message.includes('Invalid response')) {
        throw new Error('Token expired');
      }
      throw new Error(response.data.errors[0].message);
    }
    return response.data.data.Countries;
  }

  async getPOS(
    clientId: number,
    integrationId?: number
  ): Promise<IPointOfSale[]> {
    const query = queries.getPos();
    const response = await this.graphql.post('/graphql', {
      query,
      variables: {
        ...variables.getPOS({
          clientId,
          integrationId,
        }),
      },
    });
    if ('errors' in response.data) {
      if (response.data.errors[0].message.includes('Invalid response')) {
        throw new Error('Token expired');
      }
      throw new Error(response.data.errors[0].message);
    }
    return response.data.data.POS;
  }

  async getPointOfSaleById(pointOfSaleId?: number): Promise<IPointOfSale> {
    const response = await this.axios.get(`/api/pos/${pointOfSaleId}`);
    const pointOfSale: IPointOfSale = response.data;
    return pointOfSale;
  }

  async getIntegrationById(integrationId: number): Promise<IIntegration> {
    const response = await this.axios.get(`/api/integrations/${integrationId}`);
    const integration: IIntegration = response.data;
    return integration;
  }

  async getCommunes(): Promise<ICommune[]> {
    const response = await this.axios.get('/api/communes', {
      params: {
        range: '[0, 22000]',
      },
    });
    const communes: ICommune[] = response.data;
    return communes;
  }

  async getCouriers(
    filter = {},
    sort = { order: 'asc', by: 'name' },
  ): Promise<ICourier[]> {
    const sortParam = JSON.stringify([sort.by, sort.order]);
    const response = await this.axios.get('/api/couriers', {
      params: {
        sort: sortParam,
        range: '[0, 500]',
        filter: JSON.stringify(filter),
      },
    });
    return response.data;
  }

  async getCourierById(courierId?: number): Promise<ICourier> {
    const response = await this.axios.get(`/api/couriers/${courierId}`);
    const courier: ICourier = response.data;
    return courier;
  }

  async getCommuneById(communeId?: number): Promise<ICommune> {
    const response = await this.axios.get(`/api/communes/${communeId}`);
    const commune: ICommune = response.data;
    return commune;
  }

  async getProductById(productId: number): Promise<IProduct> {
    const response = await this.axios.get(`/api/products/${productId}`);
    const product: IProduct = response.data;
    return product;
  }

  async getProductsBySku(
    productsSku: string[],
    pointOfSaleId: number,
    clientId: number
  ): Promise<{ Product: IProduct }[]> {
    const response = await this.graphql.post('graphql', {
      query: queries.getProductsBySku(),
      variables: {
        ...variables.getProductsBySku({
          pointOfSaleId,
          clientId,
          productsSku,
        }),
      },
    });
    return response.data.data.PointOfSaleProducts;
  }

  async getProductWithStock(
    productId: number,
    localId: number
  ): Promise<IProductWithStock> {
    const response = await this.graphql.post('graphql', {
      query: queries.getProductWithStock(),
      variables: {
        productId,
        localId,
      },
    });
    return {
      ...response.data.data.Products[0],
      Stock: response.data.data.Products[0].Stocks[0] || { available: 0 },
    };
  }

  async searchProducts(
    pointOfSaleId: number,
    input: string
  ): Promise<IProduct[]> {
    type responseType = {
      data: { PointOfSaleProducts: IPointOfSaleProductWithProduct[] };
    };
    const response = await this.graphql.post<responseType>('graphql', {
      query: queries.searchProducts(),
      variables: {
        pointOfSaleId,
        input: `%${input}%`,
      },
    });
    const data = response.data.data.PointOfSaleProducts.map(
      (pointOfSaleProduct) => pointOfSaleProduct.Product
    );
    return data;
  }

  async searchProductsWithStock(
    pointOfSaleId: number,
    localId: number,
    input: string
  ): Promise<IProductWithStock[]> {
    type responseType = {
      data: { PointOfSaleProducts: IPointOfSaleProductWithProduct[] };
    };
    const response = await this.graphql.post<responseType>('graphql', {
      query: queries.searchProductsWithStock(),
      variables: {
        pointOfSaleId,
        localId,
        input: `%${input}%`,
      },
    });
    const data = response.data.data.PointOfSaleProducts.map(
      (pointOfSaleProduct) => {
        const product = pointOfSaleProduct.Product;
        return {
          ...product,
          Stock: product.Stocks[0] || { available: 0, onHand: 0 },
        };
      }
    );
    return data;
  }

  async createOrderDetail(item: IOrderDetailCreate): Promise<void> {
    try {
      await this.axios.post('/api/order-details', item);
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(
          `Error enviando solicitud:<br>${error.response?.data?.message}`
        );
      }
    }
  }

  async deleteOrderDetail(itemId: number): Promise<void> {
    try {
      await this.axios.delete(`/api/order-details/${itemId}`);
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(
          `Error enviando solicitud:<br>${error.response?.data?.message}`
        );
      }
    }
  }

  async updateOrderDetails(items: Partial<IOrderDetail>[]) {
    try {
      await this.axios.put(`/api/order-details`, items);
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(
          `Error enviando solicitud:<br>${error.response?.data?.message}`
        );
      }
    }
  }

  async cancelShipment(shipmentId: number): Promise<void> {
    try {
      await this.axios.post(`/api/shipments/${shipmentId}/cancel`);
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(
          `Error enviando solicitud:<br>${error.response?.data?.message}`
        );
      }
    }
  }

  async finishShipment(shipmentId: number): Promise<void> {
    try {
      await this.axios.post(`/api/shipments/${shipmentId}/finish`);
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(
          `Error enviando solicitud:<br>${error.response?.data?.message}`
        );
      }
    }
  }

  async createPickupDelivery(
    orderId: number,
    receiverInfo: IShipmentReceiverInfo
  ): Promise<void> {
    try {
      await this.axios.post(
        `/api/orders/${orderId}/pickup-delivered`,
        receiverInfo
      );
      return;
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(
          `Error enviando solicitud:<br>${error.response?.data?.message}`
        );
      }
    }
  }

  async createTransferClientOrder(
    clientOrderData: Partial<IClientOrder>,
    destinationLocalId: number | undefined
  ): Promise<number> {
    try {
      const response = await this.axios.post('/api/client-orders/transfer-order', {
        ...clientOrderData,
        destinationLocalId,
      });
      const transfer = response.data.id;
      return transfer;
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(
          `Error enviando solicitud:<br>${error.response?.data?.message}`
        );
      }
      throw error;
    }
  }

  async getProductLocationsGraphql({
    rowsPerPage = 5,
    page = 0,
    sort = { by: 'id', order: 'desc' },
    LocalId,
    ClientId,
    search,
  }: IFetchProductLocationsOptions): Promise<{
    productLocations: IProductLocation[];
    count: number;
  }> {
    const response = await this.graphql.post('/graphql', {
      query: queries.getProductLocations(rowsPerPage, rowsPerPage * page),
      variables: {
        ...variables.getProductLocations({
          LocalId,
          ClientId,
          search,
        }),
        ...sorting.ProductLocationSorting(sort),
      },
    });
    if ('errors' in response.data) {
      if (response.data.errors[0].message.includes('Invalid response')) {
        throw new Error('Token expired');
      }
      throw new Error(response.data.errors[0].message);
    }
    const productLocations: IProductLocation[] =
      response.data.data.ProductLocations.map(
        (productLocation: IProductLocationGraphqlObject) => {
          return {
            id: productLocation.id,
            LocationId: productLocation.Location.id,
            ProductId: productLocation.Product.id,
            LotId: productLocation.Lot?.id,
            onHand: productLocation.onHand,
            locationName: productLocation.Location.name,
            productName: productLocation.Product.name,
            sku: productLocation.Product.sku,
            expirationDate: productLocation.Lot?.expirationDate,
            ClientId: productLocation.Product.ClientId,
          };
        }
      );
    const count: number =
      response.data.data.ProductLocations_aggregate.aggregate.total;
    return {
      productLocations: productLocations,
      count: count,
    };
  }


  async publishInventory(clientId: number, localId: number): Promise<void> {
    try {
      await this.axios.post('/api/products/publish-inventory', {
        clientId,
        localId,
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(
          `Error enviando solicitud:<br>${error.response?.data?.message}`
        );
      }
    }
  }

  async getVTEXWarehouses(posId: number): Promise<IVTEXWarehouse[]> {
    try {
      const response = await this.axios.get(
        `/integrations/pos/${posId}/vtex/api/warehouses`
      );
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(
          `Error enviando solicitud:<br>${error.response?.data?.message}`
        );
      }
      throw error;
    }
  }

  async getVTEXSalesChannels(posId: number): Promise<IVTEXSalesChannel[]> {
    try {
      const response = await this.axios.get(
        `/integrations/pos/${posId}/vtex/api/sales-channels`
      );
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(
          `Error enviando solicitud:<br>${error.response?.data?.message}`
        );
      }
      throw error;
    }
  }

  async configureVTEXLocal(
    posId: number,
    data: { localId: number; externalId: string; salesChannelId: string }
  ): Promise<void> {
    try {
      await this.axios.post(
        `/integrations/pos/${posId}/vtex/api/onboarding`,
        data
      );
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(
          `Error configurando local:<br>${error.response?.data?.message}`
        );
      }
      throw error;
    }
  }

  async getPointOfSaleLocals(posId: number): Promise<IPointOfSaleLocal[]> {
    const response = await this.axios.get('/api/point-of-sale-locals', {
      params: {
        range: '[0, 500]',
        filter: JSON.stringify({ PointOfSaleId: posId }),
      },
    });
    const pointOfSaleLocals: IPointOfSaleLocal[] = response.data;
    return pointOfSaleLocals;
  }

  async createPointOfSaleLocal(
    data: Partial<IPointOfSaleLocal>
  ): Promise<void> {
    try {
      await this.axios.post('/api/point-of-sale-locals', data);
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(
          `Error creando PointOfSaleLocal:<br>${error.response?.data?.message}`
        );
      }
      throw error;
    }
  }

  async updatePointOfSaleLocal(
    pointOfSaleLocal: IPointOfSaleLocal
  ): Promise<void> {
    try {
      const { id, externalId, updatesStock } = pointOfSaleLocal;
      await this.axios.put(`/api/point-of-sale-locals/${id}`, {
        externalId,
        updatesStock,
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(
          `Error actualizando PointOfSaleLocal:<br>${error.response?.data?.message}`
        );
      }
      throw error;
    }
  }

  async deletePointOfSaleLocal(posLocalId: number): Promise<void> {
    try {
      await this.axios.delete(`/api/point-of-sale-locals/${posLocalId}`);
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(
          `Error eliminando PointOfSaleLocal:<br>${error.response?.data?.message}`
        );
      }
      throw error;
    }
  }

  async getTransferClientOrders({
    filter = {},
    page = 0,
    rowsPerPage = 1000,
    sort = [],
  }: IApiResourceOptions): Promise<ITransferClientOrder[]> {
    try {
      const query = queries.getTransferClientOrders(rowsPerPage, rowsPerPage * page);
      const response = await this.graphql.post('/graphql', {
        query,
        variables: {
          ...variables.getTransferClientOrdersVariables({
            filter,
            sort,
          }),
        },
      });
      if ('errors' in response.data) {
        if (response.data.errors[0].message.includes('Invalid response')) {
          throw new Error('Token expired');
        }
        throw new Error(response.data.errors[0].message);
      }

      const transferOrders = response.data.data.ClientOrders.map((co: any) => ({
        ...co,
        OrderReceptions: co.Orders.flatMap((o: any) => o.OrderReceptions),
      }));
      return transferOrders;
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.data.message);
      }
      throw new Error('Unexpected error');
    }
  }
}
