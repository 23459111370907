import { useContext, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import {
  IAddress,
  ICommune,
  ICourier,
  ICustomChangeEvent,
  IOrder,
} from '../../interfaces';
import EditButtons from '../../components/EditButtons';
import { GlobalContext } from '../../store';
import { alert } from '../../lib/utils';
import { Button } from '@mui/material';
import { ISelectOptions } from '../../components/Order/CustomSelectInput';
import CustomInputField from '../../components/Order/CustomInputField';
import { shippingTypeAvailableCouriers } from '../../lib/orders';

type ContextType = { order: IOrder };

const OrderReship = () => {
  const { api, context } = useContext(GlobalContext);
  const { order } = useOutletContext<ContextType>();

  const lastShipmentIdx = order.Shipments.map((sh, idx) => [sh.id, idx]).reduce(
    (r, a) => (a[0] > r[0] ? a : r)
  )[1];
  const lastShipment = order.Shipments[lastShipmentIdx];
  const [shippingType, setShippingType] = useState<string>(order.shippingType);
  const [courier, setCourier] = useState<ICourier | null>(lastShipment.Courier);
  const [commune, setCommune] = useState<string>(order.ClientOrder.Commune?.name || '');
  const [address1Input, setAddress1Input] = useState<string>('');
  const [address2Input, setAddress2Input] = useState<string>('');
  const [changeShippingAddress, setChangeShippingAddress] =
    useState<boolean>(false);
  const navigate = useNavigate();

  async function handleSubmit() {
    if (!courier) {
      alert(
        'error',
        'Debes seleccionar un courier'
      );
      return;
    }
    if (changeShippingAddress && (!address1Input || !commune)) {
      alert(
        'error',
        'La comuna y dirección son obligatorios. Por favor, rellena los datos.'
      );
      return;
    }
    context.startLoading();
    try {
      if (changeShippingAddress) {
        const newAddress: IAddress = {
          address1: address1Input,
          address2: address2Input,
          commune: commune,
        };
        await api.reshipOrder(order.id, { courierId: courier.id, shippingType, newAddress });
      } else {
        await api.reshipOrder(order.id, { courierId: courier.id, shippingType });
      }
      navigate('../');
      window.location.reload();
    } catch (error) {
      if (error instanceof Error) {
        alert('error', error.message);
      }
    }
    context.finishLoading();
  }

  function handleCourierChange(event: ICustomChangeEvent) {
    setCourier(event.target.item as ICourier);
  }

  function handleShippingTypeChange(event: ICustomChangeEvent) {
    setShippingType(event.target.value as string);
    setCourier(null);
  }

  function selectOptions(): ISelectOptions {
    if (order.ClientOrder.OrderType.name === 'WO') {
      return [
        {
          value: 'pickup',
          disabled: false,
        },
        {
          value: 'same-day',
          disabled: false,
        },
      ];
    }
    return ['pickup', 'express', 'same-day', 'next-day', 'courier', 'shopper']
      .map((x) => ({ value: x, disabled: false }))
  }

  return (
    <>
      <div className="FormBorder">
        <h2 className="Row Center">Re-pedir Envío</h2>
        <div className="OrderContainer Center">
          <form>
          <CustomInputField
              label="Tipo"
              inputType="select"
              value={shippingType}
              editMode={true}
              onChange={handleShippingTypeChange}
              selectOptions={selectOptions()}
            />
            <CustomInputField
                label="Courier"
                dataKey="name"
                editMode={true}
                onChange={handleCourierChange}
                inputType="autocomplete"
                autocompleteDefaultValue={courier}
                value={courier?.name}
                fetchData={async () => api.getCouriers({name: shippingTypeAvailableCouriers[shippingType]})}
                dataFieldProps={{
                  data: courier,
                  getData: () => api.getCourierById(undefined),
                  setData: setCourier,
                }}
              />
            {changeShippingAddress && (
              <>
               <CustomInputField
                  label="Comuna"
                  dataKey="name"
                  editMode={true}
                  onChange={(event) =>
                    setCommune(
                      event.target.item
                        ? (event.target.item as ICommune).name
                        : ''
                    )
                  }
                  inputType="autocomplete"
                  autocompleteDefaultValue={order.ClientOrder.Commune}
                  fetchData={async () => api.getCommunes()}
                  dataFieldProps={{
                    data: null,
                    getData: () => api.getCommuneById(order.ClientOrder.CommuneId),
                    setData: setCommune,
                  }}
                />
                <CustomInputField
                  label="Direccion 1"
                  value={address1Input || ''}
                  editMode={true}
                  onChange={(e) => setAddress1Input(e.target.value)}
                />
                <CustomInputField
                  label="Direccion 2"
                  value={address2Input || ''}
                  editMode={true}
                  onChange={(e) => setAddress2Input(e.target.value)}
                />
                <div className='Row Center'>
                  <Button
                    variant="contained"
                    color="info"
                    onClick={() =>
                      setChangeShippingAddress(!changeShippingAddress)
                    }
                  >
                    Dejar dirección actual
                  </Button>
                </div>
                
              </>
            )}
            {!changeShippingAddress && (
              <div className='Row Center'>
                <Button
                  variant="contained"
                  color="info"
                  onClick={() => setChangeShippingAddress(!changeShippingAddress)}
                >
                  Cambiar dirección envío
                </Button>
              </div>
            )}
            <EditButtons
              editMode
              onCancel={() => navigate('../')}
              onSave={handleSubmit}
            />
          </form>
        </div>
      </div>
    </>
  );
};

export default OrderReship;
