import { ChangeEvent, useContext, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { IOrder, IShipment } from '../../interfaces';
import EditButtons from '../../components/EditButtons';
import { GlobalContext } from '../../store';
import { alert } from '../../lib/utils';
import { confirm } from '../../lib/utils';
import CustomInputField from '../../components/Order/CustomInputField';
import PickingErrors from '../../components/Order/PickingErrors';
import { IPickingError } from '../../interfaces/IPickingError';
import generateKey from '../../helpers/generateKey';

type ContextType = { order: IOrder };

const nonSlowShippableStatus = ['delivered', 'returned', 'returning', 'lost', 'broken', 'pending', 'created', 'cancelled'];

const OrderGenerateIncidence = () => {
  const { api, context } = useContext(GlobalContext);
  const { order } = useOutletContext<ContextType>();

  const [incidenceReason, setIncidenceReason] = useState<string>('');
  const [compromisedType, setCompromisedType] = useState<string | null>(null);
  const [pickingErrorData, setPickingErrorData] = useState<IPickingError[]>([]);
  const [wantsDuplicate, setWantsDuplicate] = useState<boolean | null>(null);
  const navigate = useNavigate();

  const handleOrderGenerateIncidence = async () => {
    if (!(await confirm('Vas a <b>generarle una incidencia</b> a la orden.'))) {
      return;
    }
    context.startLoading();
    try {
      await api.generateIncidence(order.id, incidenceReason, {
        ...(compromisedType && { compromisedType }),
        ...(wantsDuplicate && { wantsDuplicate }),
        ...(pickingErrorData && { pickingErrorData }),
      });
      navigate(`../../${order.ClientOrderId}`);
      window.location.reload();
    } catch (error) {
      if (error instanceof Error) {
        alert('error', error.message);
      }
    }
    context.finishLoading();
  };

  async function handleAddPickingError(item: IPickingError) {
    try {
      item.ReactComponentId = generateKey(item.ProductId);
      setPickingErrorData((prev) => {
        return [...prev, item];
      });
    } catch (error) {
      if (error instanceof Error) {
        alert('error', error.message);
      }
    }
  }

  async function handleUpdatePickingError(
    key: string,
    id: string | number | symbol,
    value: number | string
  ) {
    try {      
      // update the picking error with the new data
      setPickingErrorData((prev) => {
        return prev.map((pickingError) => {
          if (pickingError.ReactComponentId === key) {
            return {
              ...pickingError,
              [id]: value || null,
            };
          }
          return pickingError;
        });
      });
    } catch (error) {
      if (error instanceof Error) {
        alert('error', error.message);
      }
    }
  }

  async function handleDeletePickingError(item: IPickingError) {
    try {
      setPickingErrorData((prev) => {
        return prev.filter((pickingError) => pickingError.ReactComponentId !== item.ReactComponentId);
      });
    } catch (error) {
      if (error instanceof Error) {
        alert('error', error.message);
      }
    }
  }

  function disableSlowShippping(order: IOrder) {
    if (order.shippingType === 'pickup' || !order.activeShipment || order.incidenceReason) {
      return true;
    }

    return nonSlowShippableStatus.includes(order.activeShipment?.status as string);
  }

  function disableCompromisedByCourier(order: IOrder) {
    const shipments = order.Shipments;
    if (!shipments.length) {
      return true;
    }
  
    const lastShipmentIdx = shipments.map((sh, idx) => [sh.id, idx]).reduce(
      (r, a) => (a[0] > r[0] ? a : r)
    )[1];
    const lastShipment = order?.Shipments[lastShipmentIdx] as IShipment;
    return ['pending', 'created', 'lost', 'cancelled'].includes(lastShipment?.status) 
  }

  function lastShipmentStatusLogs(order: IOrder) {
    const shipments = order.Shipments;
  
    const lastShipmentIdx = shipments.map((sh, idx) => [sh.id, idx]).reduce(
      (r, a) => (a[0] > r[0] ? a : r)
    )[1];
    const lastShipment = order?.Shipments[lastShipmentIdx] as IShipment;
    return lastShipment.ShipmentLogs.map((sl) => sl.status);
  }

  function canHaveDuplicate() {
    return pickingErrorData.some((x) => ['missing', 'broken'].includes(x.type as string));
  }

  return (
    <>
      <div className="FormBorder">
        <h2 className="Row Center">Generar Incidencia</h2>
        <div className="OrderContainer Center">
          <form>
            <CustomInputField
              inputType="select"
              editMode={true}
              value={incidenceReason}
              label="Incidencia del Pedido"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setIncidenceReason(e.target.value)
              }
              selectOptions={[
                {
                  value: 'client-slow-shipping',
                  label: 'Envío Lento - Solicitud Cliente',
                  disabled: disableSlowShippping(order),
                },
                {
                  value: 'nomad-slow-shipping',
                  label: 'Envío Lento - Solicitud Nomad',
                  disabled: disableSlowShippping(order),
                },
                {
                    value: 'compromised-shipment',
                    label: 'Envío Comprometido',
                    disabled: disableCompromisedByCourier(order),
                  },
                {
                  value: 'creation-error',
                  label: 'Falla de creación',
                  disabled: order.activeShipment?.status === 'delivered' || order.incidenceReason !== null,
                },
                {
                  value: 'picking-error',
                  label: 'Error de pickeo',
                  disabled: !(order.activeShipment?.status === 'delivered'),
                },
                {
                  value: 'pickup-expired',
                  label: 'Pickup expirado',
                  disabled: !(order.activeShipment?.status === 'created' && order.shippingType === 'pickup'),
                },
              ]}
            />
            {incidenceReason === 'compromised-shipment' && (
              <CustomInputField
                inputType="select"
                editMode={true}
                value={compromisedType || ''}
                label="Motivo Envío Comprometido"
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setCompromisedType(e.target.value)
                }
                selectOptions={[
                  {
                    value: 'lost',
                    label: 'Perdido',
                    disabled: lastShipmentStatusLogs(order).includes('lost'),
                  },
                  {
                    value: 'broken',
                    label: 'Roto',
                    disabled: lastShipmentStatusLogs(order).some((status) => ['lost', 'broken'].includes(status)),
                  },
                ]}
              />
            )}
            {incidenceReason === 'picking-error' && (
              <>
                <PickingErrors
                  order={order}
                  pickingErrors={pickingErrorData}
                  editMode={true}
                  onAddPickingError={handleAddPickingError}
                  onUpdatePickingError={handleUpdatePickingError}
                  pointOfSaleId={
                    order.ClientOrder?.PointOfSaleId || null
                  }
                  onDeletePickingError={handleDeletePickingError}
                />
              </>
            )}
            {incidenceReason === 'picking-error' && canHaveDuplicate() && (
              <CustomInputField
                inputType="checkbox"
                label="Requiere duplicado"
                value={String(wantsDuplicate)}
                editMode={true}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setWantsDuplicate(Boolean(e.target.value))
                }
    
              />
            )}
            <EditButtons
              editMode
              onCancel={() => navigate('../')}
              onSave={handleOrderGenerateIncidence}
            />
          </form>
        </div>
      </div>
    </>
  );
};

export default OrderGenerateIncidence;
