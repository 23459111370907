import {
  Skeleton,
  TextField,
} from '@mui/material';
import {
  ICustomChangeEvent,
  IProduct,
} from '../../interfaces';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../../store';
import { useParams } from 'react-router-dom';
import { IPickingError } from '../../interfaces/IPickingError';
import CustomInputField from './CustomInputField';
import OptionsMenu from './OptionsMenu';
  
interface PickingErrorProps {
  idx: string;
  item: IPickingError;
  onChange: (key: string, id: string | number | symbol, value: number | string) => void;
  onDelete: (item: IPickingError) => void;
  editMode?: boolean;
  isValidPickingError: (pickingError: Partial<IPickingError>, originalPickingError?: IPickingError) => boolean;
 }
  
export default function PickingError(props: PickingErrorProps) {
  const { api } = useContext(GlobalContext);
  const { localId } = useParams();
  const {
    idx,
    item,
    onChange,
    onDelete,
    editMode = true,
    isValidPickingError,
  } = props;

  const [product, setProduct] = useState<IProduct>();

  useEffect(() => {
    (async () => {
      if (localId) {
        setProduct(
          await api.getProductById(item.ProductId)
        );
      }
    })();
  }, [item]);


  function handleInputChange(event: ChangeEvent<HTMLInputElement> | ICustomChangeEvent) {
    const { value } = event.target;

    if (value) {
      const newPickingError = { ...item, amount: parseInt(String(value)) }
      if (isValidPickingError(newPickingError, item)) {
        onChange(idx, 'amount', parseInt(String(value)));
      }  
    }
  }

  function handleTypeChange(event: ChangeEvent<HTMLInputElement> | ICustomChangeEvent) {
    const { value } = event.target;
    if (value){
      const newPickingError = { ...item, type: String(value) };
      if (isValidPickingError(newPickingError, item)) {
        onChange(idx, 'type', value);
      }  
    }  
  }


  function handleDelete(pickingError: IPickingError) {
    onDelete(pickingError);
  }

  if (!product) {
    return (
      <div className="Grow">
        <Skeleton />
      </div>
    );
  }

  return (
    <div className="Border">
      <div className="Row OrderDetail">
        <div className="Flex2">
          <TextField
            type="number"
            label="Cantidad"
            value={item.amount}
            onChange={handleInputChange}
          />
        </div>
        <div className="Flex6">
          <div>
            <div className="DetailHeader">
              <p className="Sku ProductName">{product.sku}</p>
            </div>
            <p className="ProductName">
              {product.name}
            </p>
          </div>
        </div>
        <div className="Flex2">
            <CustomInputField
              inputType="select"
              editMode={editMode}
              value={item.type}
              label=""
              onChange={handleTypeChange}
              selectOptions={[
                  {
                    value: 'broken',
                    label: 'Rotos',
                    disabled: !isValidPickingError({ ...item, type: 'broken' }, item),
                  },
                  {
                    value: 'missing',
                    label: 'Faltantes',
                    disabled: !isValidPickingError({ ...item, type: 'missing' }, item),
                  },
                  {
                    value: 'extra',
                    label: 'Extra',
                    disabled: !isValidPickingError({ ...item, type: 'extra' }, item),
                  },
                  {
                    value: 'extra-broken',
                    label: 'Extra y Rotos',
                    disabled: !isValidPickingError({ ...item, type: 'extra-broken' }, item),
                  },
              ]}
            />
          </div>

        <div className="Flex1">
          <OptionsMenu
            item={item}
            onDelete={handleDelete}
          />
        </div>
      </div>
    </div>
  );
}
