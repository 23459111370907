import {
    DateField,
    Edit,
    FormDataConsumer,
    NumberInput,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    TextInput,
  } from 'react-admin';
  import { JsonInput } from 'react-admin-json-view';
  import { transformInput } from '../../../lib/utils';
  import { useContext, useEffect, useState } from 'react';
  import { GlobalContext } from '../../../store';
  import { ICountry } from '../../../interfaces';
  
  export const ClientOrderEdit = () => {
    const { api } = useContext(GlobalContext);
    const [regionId, setRegionId] = useState<number | null>(null);
    const [countryId, setCountryId] = useState<number | null>(null);
    const [clientId, setClientId] = useState<number | null>(null);
    const [orderTypeId, setOrderTypeId] = useState<number | null>(null);
    const [countries, setCountries] = useState<ICountry[]>([]);
    const [intialValuesAlreadySet, setIntialValuesAlreadySet] =
      useState<boolean>(true);
  
    function setInitialValues(communeId: number) {
      if (intialValuesAlreadySet && countries.length > 0) {
        for (let i = 0; i < countries.length; i += 1) {
          const checkCountry = countries[i];
          for (let j = 0; j < checkCountry.Regions.length; j += 1) {
            const checkRegion = checkCountry.Regions[j];
            for (let k = 0; k < checkRegion.Communes.length; k += 1) {
              const checkCommune = checkRegion.Communes[k];
              if (checkCommune?.id === communeId) {
                setCountryId(checkCountry.id);
                setRegionId(checkRegion.id);
              }
            }
          }
        }
        setIntialValuesAlreadySet(false);
      }
    }
  
    useEffect(() => {
      api.getCountries().then((newCountries) => {
        setCountries(newCountries);
      });
    }, []);
  
    return (
      <Edit transform={transformInput}>
        <SimpleShowLayout>
          <TextField source="id" />
          <DateField source="createdAt" showTime />
          <DateField source="updatedAt" showTime />
        </SimpleShowLayout>
        <SimpleForm>
          <TextInput source="customerName" />
          <TextInput source="date" />
          <TextInput source="code" />
          <TextInput source="platformCode" />
          <TextInput source="customerEmail" />
          <TextInput source="note" multiline />
          <JsonInput
            source="shippingAddress"
            jsonString={false} // Set to true if the value is a string, default: false
            defaultValue={{
              address1: '',
              address2: '',
              commune: '',
            }}
            reactJsonOptions={{
              // Props passed to react-json-view
              name: null,
              collapsed: true,
              enableClipboard: false,
              displayDataTypes: true,
            }}
          />
          <TextInput source="commune" />
          <TextInput source="ticketInfo" />
          <NumberInput source="globalDiscount" />
          <TextInput source="shippingType" />
          <NumberInput source="shippingDiscount" />
          <TextInput source="status" />
          <TextInput source="customerPhone" />
          <TextInput source="shippingCost" />
          <ReferenceInput source="ClientId" reference="clients" perPage={10000}>
            <SelectInput
              onChange={(e) => {
                setClientId(e.target.value);
              }}
              optionText="name"
            />
          </ReferenceInput>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              !!formData.ClientId && (
                <>
                  {setClientId(formData.ClientId)}
                  {!!clientId && (
                    <ReferenceInput
                      source="PointOfSaleId"
                      reference="pos"
                      filter={{ ClientId: clientId }}
                      perPage={10000}
                      {...rest}
                    >
                      <SelectInput optionText="name" />
                    </ReferenceInput>
                  )}
                </>
              )
            }
          </FormDataConsumer>
          <ReferenceInput source="LocalId" reference="locals" perPage={10000} filter={{ active: true }}>
            <SelectInput optionText="name" />
          </ReferenceInput>
          {countryId !== null && (
            <ReferenceInput
              source="CountryId"
              reference="countries"
              sort={{ field: 'name', order: 'ASC' }}
              perPage={100}
            >
              <SelectInput
                defaultValue={countryId}
                onChange={(e) => {
                  setCountryId(e.target.value);
                  setRegionId(null);
                }}
                optionText="name"
              />
            </ReferenceInput>
          )}
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.CommuneId !== null &&
              (setInitialValues(formData.CommuneId),
              (
                <>
                  {countryId !== null && (
                    <ReferenceInput
                      source="RegionId"
                      reference="regions"
                      sort={{ field: 'name', order: 'ASC' }}
                      filter={{ CountryId: countryId }}
                      perPage={10000}
                      {...rest}
                    >
                      <SelectInput
                        defaultValue={regionId}
                        onChange={(e) => {
                          setRegionId(e.target.value);
                        }}
                        optionText="name"
                      />
                    </ReferenceInput>
                  )}
                  <ReferenceInput
                    source="CommuneId"
                    reference="communes"
                    sort={{ field: 'name', order: 'ASC' }}
                    filter={{ RegionId: regionId }}
                    perPage={10000}
                  >
                    <SelectInput optionText="name" />
                  </ReferenceInput>
                </>
              ))
            }
          </FormDataConsumer>
          <ReferenceInput
            source="IntegrationId"
            reference="integrations"
            perPage={10000}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
          <ReferenceInput
            source="OrderTypeId"
            reference="order-types"
            perPage={1000}
            filter={{ id: [1,2,3,4] }}
          >
            <SelectInput
              onChange={(e) => {
                setOrderTypeId(e.target.value);
              }}
              optionText="name"
            />
          </ReferenceInput>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              !!formData.ClientId && !!formData.OrderTypeId && (
                <>
                  {setClientId(formData.ClientId)}
                  {setOrderTypeId(formData.OrderTypeId)}
                  {!!clientId && !!orderTypeId && (
                    <ReferenceInput
                      source="ChargeRateId"
                      reference="charge-rates"
                      filter={{ ClientId: clientId, OrderTypeId: orderTypeId }}
                      perPage={10000}
                      {...rest}
                    >
                      <SelectInput optionText="name" />
                    </ReferenceInput>
                  )}
                </>
              )
            }
          </FormDataConsumer>
        </SimpleForm>
      </Edit>
    );
  };
  