import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

interface BackProps {
  path?: string;
}

export default function Back({ path }: BackProps) {
  const navigate = useNavigate();
  return (
    <div>
        <Button variant="text" onClick={()=> navigate(path || '../')}>
          <div className="ButtonBack">
            <ChevronLeftIcon />
            Volver
          </div>
        </Button>
    </div>
  );
}
