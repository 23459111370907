import {
  Link,
  Outlet,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import {
  IOrder,
  INotFoundProduct,
  IOrderBackorders,
  IOrderDetail,
  IOrderDetailCreate,
} from '../../interfaces';
import OrderDetails from '../../components/Order/OrderDetails';
import OrderData from '../../components/Order/OrderData';
import {
  isEqualToOrder,
  isEqualToOrderDetails,
  isOrderDetailsValid,
  statusMappingFilters,
  updatedOrderDetails,
} from '../../lib/orders';
import { confirm } from '../../lib/utils';
import EditButtons from '../../components/EditButtons';
import { Button } from '@mui/material';
import NotFoundProducts from '../../components/Order/NotFoundProducts';
import Back from '../../components/Back';
import { IShipment } from '../../interfaces/IShipment';
import { GlobalContext } from '../../store';
import { alert } from '../../lib/utils';
import PageLoader from '../../components/PageLoader';
import generateKey from '../../helpers/generateKey';
import { isActive } from '../../lib/shipments';

const OrderShow = () => {
  const {
    api,
    user,
    context: { startLoading, finishLoading },
  } = useContext(GlobalContext);
  const { orderId } = useParams();
  const [order, setOrder] = useState<IOrder>();
  const [updatedOrder, setUpdatedOrder] = useState<IOrder>();
  const navigate = useNavigate();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [orderBackorders, setOrderBackorders] = useState<IOrderBackorders>({});
  const [isOnHold, setIsOnHold] = useState<boolean>(false);
  const [shipment, setShipment] = useState<IShipment>();
  const [activeClient, setActiveClient] = useState<boolean>(false);

  const returnableIncidences = ['picking-error', 'client-slow-shipping', 'nomad-slow-shipping', 'compromised-shipment', 'creation-error'];

  useEffect(() => {
    (async () => {
      const { orders } = await api.getOrders({
        filter: { ids: [Number(orderId)] },
      });
      const order= orders[0];
      order.activeShipment = order.Shipments.filter((s) => isActive(s))[0]
      if (order === null) {
        navigate('../');
      }
      if (order) {
        const orderSetup = {
          ...order,
          OrderDetails: await Promise.all(
            order.OrderDetails.map(async (item) => {
              const product = await api.getProductById(item.ProductId);
              item.sku = product?.sku;
              item.name = product?.name;
              item.ReactComponentId = generateKey(item.ProductId);
              item.PackComponents = product.isPack
                ? await api.getPackComponents(item.ProductId)
                : [];
              item.isPack = product.isPack;
              return item;
            }),
          ),
        };
        setOrder(orderSetup);
        setUpdatedOrder(orderSetup);
        if (order.activeShipment) {
          setShipment(order.activeShipment);
        }
        if (order.status === 'on-hold') {
          setIsOnHold(true);
          const backorders = await api.getOrderBackorders(order.id);
          if (backorders) {
            setOrderBackorders(backorders);
          }
        }
      }
    })();
  }, []);

  useEffect(() => {
    if (!order || !order.ClientOrder?.ClientId) return;
    (async () => {
      const client = await api.getClientById(order.ClientOrder?.ClientId);
      setActiveClient(client.active);
    })();
  }, [order]);

  function reset() {
    setEditMode(false);
    window.location.reload();
  }

  function hasLastShipmentStartedReturn(order: IOrder){
    const lastShipment = order.Shipments[0];
    if (lastShipment.ShipmentLogs.find((sl) => sl.status === 'returning')) {
      return true;
    }
  }

  async function handleAddOrderDetail(item: IOrderDetailCreate) {
    try {
      const product = await api.getProductById(item.ProductId);
      item.sku = product?.sku;
      item.name = product?.name;
      item.ReactComponentId = generateKey(item.ProductId);
      setUpdatedOrder((prev) => {
        if (prev) {
          return {
            ...prev,
            OrderDetails: [...prev.OrderDetails, item],
          };
        }
        return prev;
      });
    } catch (error) {
      if (error instanceof Error) {
        alert('error', error.message);
      }
    }
  }

  async function handleDeleteOrderDetail(item: IOrderDetail) {
    if (item.id === undefined) {
      setUpdatedOrder((prev) => {
        if (prev) {
          return {
            ...prev,
            OrderDetails: (prev.OrderDetails as IOrderDetail[]).filter(
              (x) => x.ReactComponentId !== item.ReactComponentId
            ),
          };
        }
        return prev;
      });
    } else {
      if (!(await confirm('Vas a eliminar un producto de la orden.'))) {
        return;
      }
      try {
        startLoading();
        await api.deleteOrderDetail(item.id as number);
        window.location.reload();
      } catch (error) {
        if (error instanceof Error) {
          alert('error', error.message);
        }
      }
    }
  }

  async function handleShipmentReturn(order: IOrder) {
    if (!(await confirm('Vas a <b>iniciar la devolución</b> del envío.'))) {
      return;
    }
    const lastShipment = order.Shipments[0];
    if (!lastShipment) return;
    startLoading();
    try {
      await api.updateShipment(lastShipment.id, { status: 'returning' });
      window.location.reload();
    } catch (error) {
      if (error instanceof Error) {
        alert('error', 'Se ha producido un error al intentar finalizar el envío');
      }
    }
    finishLoading();
  }

  async function handleFinishShipment(order: IOrder) {
    if (!(await confirm('Vas a <b>finalizar</b> el envío.'))) {
      return;
    }
    if (!order.activeShipment) return;
    startLoading();
    try {
      await api.finishShipment(order.activeShipment.id);
      window.location.reload();
    } catch (error) {
      if (error instanceof Error) {
        alert('error', 'Se ha producido un error al intentar finalizar el envío');
      }
    }
    finishLoading();
  }

  async function handleOrderCancel(order: IOrder) {
    if (!(await confirm('Vas a cancelar la orden.'))) {
      return;
    }
    startLoading();
    try {
      await api.cancelOrder(order.id);
      window.location.reload();
    } catch (error) {
      if (error instanceof Error) {
        alert('error', error.message);
      }
    }
    finishLoading();
  }

  function showReshipButton(order: IOrder) {
    if (order.ClientOrder.status === 'cancelled' || order.incidenceReason) {
      return false;
    }
    return ['returned', 'cancelled', 'pending'].includes(order.Shipments[0].status);
  }

  function showShipmentReturnButton(order: IOrder) {
    if (!returnableIncidences
      .includes(order.incidenceReason as string) || hasLastShipmentStartedReturn(order)    
    ) {
      return false;
    }
    if (order.incidenceReason === 'picking-error'
    && !order.PickingErrors.some((pe) => ['extra', 'extra-broken', 'broken'].includes(pe.type || ''))) {
      return false;
    }
    return !['pending', 'created', 'returned', 'lost', 'cancelled', 'returning'].includes(order.Shipments[0].status);
  }

  function showFinishShipmentButton(order: IOrder) {
    return order.activeShipment && (['created', 'returning', 'undelivered'].includes(order.activeShipment.status) 
      || order.activeShipment.status.includes('Other status:') || hasLastShipmentStartedReturn(order));
  }

  function showCancelOrderButton(order: IOrder) {
    if (['cancelled', 'fulfilled'].includes(order.status)
      || ((!order.incidenceReason && !order.ClientOrder.cancelReason)
      && order.ClientOrder.Orders.filter((o) => o.incidenceReason === null && o.status !== 'cancelled').length <= 1)) {
      return false;
    }
    if (!order.Shipments.length) {
      return true;
    }
    return ['pending', 'created', 'cancelled', 'returned', 'undelivered'].includes(order.Shipments[0].status) 
    || order.Shipments[0].status.includes('Other status:');
  }

  function showGenerateIncidenceButton(order: IOrder) {
    return order.incidenceReason === null
    || (
      returnableIncidences.includes(order.incidenceReason)
      && hasLastShipmentStartedReturn(order) && order.Shipments[0].status !== 'lost'
    );
  }

  function showDeliverPickup(order: IOrder) {
    return order.incidenceReason === null
     && order.shippingType === 'pickup'
     && order.status === 'fulfilled'
     && order.Shipments.length 
     && order.activeShipment
     && order.activeShipment.status === 'created';
  }

  if (!order || !updatedOrder) {
    return <PageLoader />;
  }

  const handleOrderSave = async () => {
    try {
      startLoading();
      // Save order data (left panel)
      if (!isEqualToOrder(updatedOrder, order)) {
        // make request
        const { OrderDetails, ...newOrderData } = updatedOrder; // eslint-disable-line
        await api.updateOrder(order.id, newOrderData);
      }

      // // save order details
      const updateableItems = updatedOrder.OrderDetails.filter(
        (item) => item.id !== undefined
      );
      const itemsToUpdate: Partial<IOrderDetail>[] = updatedOrderDetails(
        updateableItems,
        order.OrderDetails
      );
      const itemsToCreate = updatedOrder.OrderDetails.filter(
        (item) => item.id === undefined
      );
      if (itemsToUpdate.length || itemsToCreate.length) {
        // get only the items that were updated
        isOrderDetailsValid(updatedOrder.OrderDetails, ['TO', 'WO'].includes(order.ClientOrder?.OrderType.name));
        // make requests
        if (itemsToUpdate.length > 0) {
          if (statusMappingFilters.completed.includes(order.status)) {
            throw new Error(
              'No puedes editar los productos de un pedido completado.'
            );
          }
          await api.updateOrderDetails(itemsToUpdate);
        }
        await Promise.all(
          itemsToCreate.map((item) => api.createOrderDetail(item))
        );
      }

      setOrder({ ...order, ...updatedOrder });

      reset();
    } catch (error) {
      if (error instanceof Error) {
        alert('error', error.message);
      }
    }
    finishLoading();
  };

  const handleChangeNotFound = async (
    data: INotFoundProduct[]
  ): Promise<boolean> => {
    if (!(await confirm('Vas a actualizar los productos no encontrados.'))) {
      return false;
    }
    startLoading();
    try {
      await api.updateOrder(order.id, { notFoundProducts: data });
      setOrder({ ...order, notFoundProducts: data });
      finishLoading();
      return true;
    } catch (e) {
      if (e instanceof Error) {
        alert('error', e.message);
      }
      finishLoading();
      return false;
    }
  };

  const handleOrderViewLabel = async () => {
    if (shipment?.labels) {
      shipment.labels.map((url) => window.open(url, '_blank'));
    }
  };

  function handleUpdatedOrderDataChange(orderData: Partial<IOrder>) {
    setUpdatedOrder((order) => {
      if (order) {
        return {
          ...order,
          ...orderData,
        };
      }
      return order;
    });
  }

  function handleUpdateOrderDetail(
    key: string,
    id: string | number | symbol,
    value: number | string
  ) {
    setUpdatedOrder((prevOrder) => {
      if (prevOrder) {
        const newOrderDetails = prevOrder.OrderDetails.map((item) => {
          if (item.ReactComponentId === key) {
            return {
              ...item,
              [id]: value || null,
            };
          }
          return item;
        });
        return {
          ...prevOrder,
          OrderDetails: newOrderDetails,
        };
      }
      return prevOrder;
    });
  }

  return (
    <>
      <Back path={`../${order.ClientOrderId}`}/>
      <div className="OrderContainer">
        <div className='Column'>
        <OrderData
          order={updatedOrder}
          setOrderData={handleUpdatedOrderDataChange}
          editMode={editMode}
        >
          <>
            {shipment?.labels && shipment.labels.length > 0 && (
              <div className="Row Center EditButtons">
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleOrderViewLabel}
                >
                  Ver etiqueta de envío
                </Button>
              </div>
            )}
          </>

          <>
            {showDeliverPickup(order) && (
                <div className="Row Center EditButtons">
                  <Link to={'pickup'}>
                    <Button variant="contained" color="secondary">
                      Entregar Pickup
                    </Button>
                  </Link>
                </div>
              )}
          </>
          <>
            {activeClient && (
            <>
              {showReshipButton(order)
                ? (
                  <div className="Row Center EditButtons">
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => navigate('reship')}
                    >
                      Re-pedir envío
                    </Button>
                  </div>
                ) : <></>
              }
              {showShipmentReturnButton(order)
                ? (
                  <div className="Row Center EditButtons">
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => handleShipmentReturn(order)}
                    >
                      Iniciar devolución a bodega
                    </Button>
                  </div>
                ) : <></>
              }
              {showFinishShipmentButton(order) 
              && (
                <div className="Row Center EditButtons">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleFinishShipment(order)}
                  >
                    {order.activeShipment?.status === 'created' ? 'Cancelar Envío' : 'Registrar Devolución' }
                  </Button>
                </div>
              )}
              {order.status !== 'fulfilled' &&
                order.activeShipment?.status === 'pending' &&
                order.incidenceReason === null && (
                  <div className="Row Center EditButtons">
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => navigate('reallocate')}
                    >
                      Cambiar local
                    </Button>
                  </div>
              )}
              {showCancelOrderButton(order) && (
                <div className="Row Center EditButtons">
                  <Button
                    variant="contained"
                    color="warning"
                    onClick={() => handleOrderCancel(order)}
                  >
                    Cancelar Pedido
                  </Button>
                </div>
              )}
              {showGenerateIncidenceButton(order) && (
                <div className="Row Center EditButtons">
                  <Button
                    variant="contained"
                    color="warning"
                    onClick={() => navigate('generate-incidence')}
                  >
                    Generar Incidencia
                  </Button>
                </div>
              )}
            </>
          )}
        </>
        </OrderData>
        </div>
        <OrderDetails
          order={updatedOrder}
          editMode={editMode}
          onAddOrderDetail={handleAddOrderDetail}
          onUpdateOrderDetail={handleUpdateOrderDetail}
          pointOfSaleId={
            updatedOrder.ClientOrder?.PointOfSaleId || order.ClientOrder?.PointOfSaleId || null
          }
          isOnHold={isOnHold}
          backorders={orderBackorders}
          onDeleteOrderDetail={handleDeleteOrderDetail}
        >
          <NotFoundProducts
            data={order.notFoundProducts}
            onChange={handleChangeNotFound}
          />
          <>
            {activeClient && (
              <EditButtons
                saveDisabled={
                  isEqualToOrder(updatedOrder, order) &&
                  isEqualToOrderDetails(
                    updatedOrder.OrderDetails,
                    order.OrderDetails
                  )
                }
                editDisabled={
                  statusMappingFilters.completed.includes(order.status) &&
                  user.role !== 'admin'
                }
                editMode={editMode}
                onCancel={reset}
                onEdit={() => setEditMode(true)}
                onSave={handleOrderSave}
              />
            )}
          </>
          <Outlet context={{ order }} />
        </OrderDetails>
      </div>
    </>
  );
};

export default OrderShow;
