import { IShipment } from "../interfaces/IShipment";

export const shipmentStatusMappingFilters: {
  all: string[];
  active: string[];
  inactive: string[];
} = {
  all: [
    'pending',
    'created',
    'ontheway',
    'cross-docking',
    'returning',
    'cross-docked',
    'collecting',
    'collected',
    'delivered',
    'returned',
    'cancelled',
    'undelivered',
  ],
  active: [
    'pending',
    'created',
    'ontheway',
    'cross-docking',
    'returning',
    'cross-docked',
    'collecting',
    'collected',
    'delivered',
    'undelivered',
    'broken',
  ],
  inactive: [ 
    'lost',
    'returned',
    'cancelled',
  ],
};

export function isActive(shipment: IShipment) {
  return !shipmentStatusMappingFilters.inactive.includes(shipment.status);
}