import { IOrder, IOrderDetail } from '../interfaces/IOrder';

interface IOrderTypeFilters {
  all: string[];
  B2C: string[];
  B2B: string[];
  TO: string[];
  WO: string[];
  Retailer: string[];
}
export const orderTypeMappingFilters: IOrderTypeFilters = {
  all: ['B2C','B2B','TO','WO', 'Retailer'],
  B2C: ['B2C'],
  B2B: ['B2B'],
  TO: ['TO'],
  WO: ['WO'],
  Retailer: ['Retailer'],
};
export type IOrderTypeFiltersKey = keyof IOrderTypeFilters;

export const shippingTypeAvailableCouriers: { [key: string]: string[] } = {
  pickup: ['pickup'],
  express: ['pedidos ya', 'uber direct'],
  'same-day': ['cabify', 'fazt', 'flota nomad'],
  'next-day': ['logicold'],
  courier: ['alas express', 'blue express', 'qs logistics'],
  shopper: ['external'],
};

export const incidenceReasonTranslation = {
  "client-slow-shipping": "Envío lento Cliente",
  "nomad-slow-shipping": "Envío lento Nomad",
  "creation-error": "Falla interna",
  "compromised-shipment": "Envío comprometido",
  "picking-error": "Error de pickeo",
  "pickup-expired": "Pickup expirado",
};

export const statusMappingFilters: {
  all: string[];
  pending: string[];
  completed: string[];
} = {
  all: [
    'pending',
    'pat-pending',
    'on-hold',
    'picking',
    'fulfilled',
    'ontheway',
    'delivered',
    'cross-docking',
    'cancelled',
    'Other status: EnProcesoDeDevolucion',
    'Other status: NoEntregado',
    'cornershop-manual',
    'cornershop-automatic',
  ],
  pending: ['pending', 'pat-pending', 'on-hold', 'picking'],
  completed: [
    'fulfilled',
    'ontheway',
    'delivered',
    'cross-docking',
    'cancelled',
    'Other status: EnProcesoDeDevolucion',
    'Other status: NoEntregado',
    'cornershop-manual',
    'cornershop-automatic',
  ],
};

export function isOrderDetailsValid(
  orderDetails: Partial<IOrderDetail>[] | undefined,
  isInternalOrder?: boolean
) {
  if (!orderDetails || orderDetails.length === 0) {
    throw new Error('El pedido debe tener al menos un producto.');
  }
  if (!isInternalOrder) {
    orderDetails.forEach((item) => {
      if (typeof item.price !== 'number') {
        throw new Error('Debe ingresar un precio para todos los productos.');
      }
    });
  }
}

export function isOrderDetailsValidCreate(order: Partial<IOrder>, isInternalOrder?: boolean) {
  isOrderDetailsValid(order.OrderDetails, isInternalOrder);
}


export function isEqualToOrder(
  newOrderData: Partial<IOrder>,
  order: Partial<IOrder>
): boolean {
  const { OrderDetails, ...orderWithoutDetails } = order;
  const { OrderDetails: newOrderDetails, ...newOrderWithoutDetails } =
    newOrderData;
  return Object.entries(newOrderWithoutDetails).every(([key, value]) => {
    return value === (orderWithoutDetails as IOrder)[key as keyof IOrder];
  });
}

export function isEqualToOrderDetails(
  newOrderDetails: Partial<IOrderDetail>[],
  orderDetails: IOrderDetail[]
): boolean {
  if (newOrderDetails.length !== orderDetails.length) {
    return false;
  }
  return newOrderDetails.every((item, idx) => {
    return Object.entries(item).every(([key, value]) => {
      return value === orderDetails[idx][key as unknown as keyof IOrderDetail];
    });
  });
}

export function updatedOrderDetails(
  newOrderDetails: Partial<IOrderDetail>[],
  orderDetails: IOrderDetail[]
): Partial<IOrderDetail>[] {
  return newOrderDetails.filter((item, idx) => {
    return !Object.entries(item).every(([key, value]) => {
      return value === orderDetails[idx][key as unknown as keyof IOrderDetail];
    });
  });
}
