import {
  BooleanField,
  Datagrid,
  DateField,
  List,
  NumberField,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
  TextInput,
} from 'react-admin';
import { AdminPagination } from '../AdminLayout';

const productFilters = [
  <TextInput label="SKU" source="sku"></TextInput>,
  <TextInput label="Name" source="name"></TextInput>,
  <ReferenceInput
    source="ClientId"
    reference="clients"
    sort={{ field: 'name', order: 'ASC' }}
    perPage={1000}
    alwaysOn
  >
    <SelectInput optionText="name" />
  </ReferenceInput>,
  <SelectInput
    source="storageType"
    choices={[
      { id: 'dry', name: 'Dry' },
      { id: 'air-conditioned', name: 'Air Conditioned' },
      { id: 'refrigerated', name: 'Refrigerated' },
      { id: 'frozen', name: 'Frozen' },
    ]}
  />,
  <SelectInput
    source="isPack"
    choices={[
      { id: true, name: 'Yes' },
      { id: false, name: 'No' },
    ]}
  />,
  <SelectInput
    source="ageVerification"
    choices={[
      { id: true, name: 'Yes' },
      { id: false, name: 'No' },
    ]}
  />,
];

export const ProductList = () => (
  <List filters={productFilters} pagination={<AdminPagination />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="sku" />
      <TextField source="rawSku" />
      <TextField source="name" />
      <ReferenceField source="ClientId" reference="clients">
        <TextField source="name" />
      </ReferenceField>
      <BooleanField source="activated" />
      <BooleanField source="isService" />
      <BooleanField source="hasModifiers" />
      <NumberField source="pvp" />
      <NumberField source="safetyStock" />
      <NumberField source="expirationAlert" />
      <TextField source="barcode" />
      <BooleanField source="isPack" />
      <BooleanField source="isWeighted" />
      <BooleanField source="needsLotTracking" />
      <BooleanField source="isFamily" />
      <BooleanField source="ageVerification" />
      <TextField source="familySku" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
      <NumberField source="weight" />
      <NumberField source="length" />
      <NumberField source="depth" />
      <NumberField source="height" />  
      <NumberField source="unitsPerBox" />
      <NumberField source="boxesPerPallet" />
    </Datagrid>
  </List>
);
