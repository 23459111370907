import { useContext, useEffect, useState } from 'react';
import { IClient, IPointOfSale } from '../../interfaces';
import { alert, capitalize } from '../../lib/utils';
import { GlobalContext } from '../../store';
import { Switch } from '@mui/material';
import Loading from '../../components/Loading';

interface WebhooksState {
  'orders/create': boolean;
  'orders/paid': boolean;
  'orders/cancelled': boolean;
  'orders/updated': boolean;
  'products/create': boolean;
  'products/update': boolean;
  'inventory_levels/update': boolean;
  carrier_service: boolean;
}
type WebhookKey = keyof WebhooksState;

const defaultWebhooks: WebhooksState = {
  'orders/create': false,
  'orders/paid': false,
  'orders/cancelled': false,
  'orders/updated': false,
  'products/create': false,
  'products/update': false,
  'inventory_levels/update': false,
  carrier_service: false,
};

const Webhooks = () => {
  const { api, functionsApi } = useContext(GlobalContext);

  const [clients, setClients] = useState<IClient[]>([]);
  const [client, setClient] = useState<IClient | null>(null);
  const [pointOfSale, setPointOfSale] = useState<IPointOfSale | null>(null);
  const [pointOfSales, setPointOfSales] = useState<IPointOfSale[]>([]);
  const [showWebhooks, setShowWebhooks] = useState<boolean>(false);
  const [initialWebhooksState, setInitialWebhooksState] = useState<WebhooksState>(defaultWebhooks);
  // Switch webhooks
  const [webhooksState, setWebhooksState] = useState<WebhooksState>(initialWebhooksState);
  const webhooksUpdated = JSON.stringify(webhooksState) !== JSON.stringify(initialWebhooksState);
  const [loading, setLoading] = useState<boolean>(false);

  async function handleSubmit() {
    if (!pointOfSale || !webhooksUpdated) {
      alert('error', 'Formulario incompleto');
      return;
    }
    try {
      setLoading(true);
      const webhookKeys = Object.keys(webhooksState);
      for (const webhook of webhookKeys) {
        const webhookKey = webhook as WebhookKey;
        if (initialWebhooksState[webhookKey] !== webhooksState[webhookKey]) {
          await functionsApi.manageShopifyWebhook(
            pointOfSale.id,
            webhooksState[webhookKey] ? 'activate' : 'deactivate',
            webhookKey
          );
        }
      }
      setLoading(false);
      setInitialWebhooksState(webhooksState);
      alert('success', 'Los cambios se enviaron correctamente');
    } catch (error) {
      alert('error', `No se enviaron los cambios: "${error}"`);
      setWebhooksState(initialWebhooksState);
      setLoading(false);
    }
  }

  useEffect(() => {
    // Set clients and default client
    setLoading(true);
    api
      .getClients({ active: true })
      .then((response) => {
        setClients(response);
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    (async () => {
      if (client) {
        const integration = (await api.getIntegrations(client.id)).find(
          (integration) => integration.name === 'shopify'
        );
        const pos = await api.getPOS(client.id);
        const result = pos.filter((p) => p.IntegrationId === integration?.id);
        setPointOfSales(result);
      }
    })();
  }, [client]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      if (pointOfSale) {
        const activeWebhooks = await functionsApi.manageShopifyWebhook(
          pointOfSale.id,
          'list'
        );
        if (typeof activeWebhooks === 'object') {
          const posState = { ...defaultWebhooks };
          const initKeys = Object.keys(posState);
          activeWebhooks.forEach((activeWebhook) => {
            if (initKeys.includes(activeWebhook)) {
              posState[activeWebhook as WebhookKey] = true;
            }
          });
          setInitialWebhooksState(posState);
          setWebhooksState(posState);
          setShowWebhooks(true);
        }
      } else {
        setShowWebhooks(false);
      }
      setLoading(false);
    })();
  }, [pointOfSale]);

  return (
    <div className="Webhooks">
      <div className="Title">Administración de Webhooks</div>
      <form className="WebhooksForm">
        <div className="InputWrapper">
          <div className="InputTitle">Cliente</div>
          <select
            name="client"
            onChange={(e) => setClient(clients[parseInt(e.target.value, 10)])}
          >
            <option value="">Selecciona un cliente</option>
            {clients.map((clientOption, idx) => (
              <option value={idx} key={clientOption.id}>
                {capitalize(clientOption.name)}
              </option>
            ))}
          </select>
        </div>
        <div className="InputWrapper">
          <div className="InputTitle">POS</div>
          <select
            name="client"
            onChange={(e) =>
              setPointOfSale(pointOfSales[parseInt(e.target.value, 10)])
            }
          >
            <option value="">Selecciona un Pos</option>
            {pointOfSales.map((posOption, idx) => (
              <option value={idx} key={posOption.id}>
                {capitalize(posOption.name)}
              </option>
            ))}
          </select>
        </div>
        {showWebhooks && !loading && (
          <>
            <div className="SubTitle">Webhooks</div>
            {Object.keys(webhooksState).map((webhook, idx) => {
              const webhookKey = webhook as WebhookKey;
              const switchLabels: { [key: string]: string } = {
                'orders/create': 'Pedido Creado',
                'orders/paid': 'Pedido Pagado',
                'orders/cancelled': 'Pedido Cancelado',
                'orders/updated': 'Pedido Actualizado',
                'products/create': 'Producto Creado',
                'products/update': 'Producto Actualizado',
                'inventory_levels/update': 'Actualización de Stock',
                carrier_service: 'Tarificador',
              };
              return (
                <div className="InputWrapperRow" key={idx}>
                  <div className="InputText">{switchLabels[webhookKey]}</div>
                  <Switch
                    checked={webhooksState[webhookKey]}
                    onChange={() =>
                      setWebhooksState((prevState) => ({
                        ...prevState,
                        [webhookKey]: !prevState[webhookKey],
                      }))
                    }
                  />
                </div>
              );
            })}
          </>
        )}
        {loading && <Loading />}
        <div
          className={`Button ${
            !pointOfSale || !webhooksUpdated ? 'disabled' : ''
          }`}
          onClick={handleSubmit}
        >
          Enviar
        </div>
      </form>
    </div>
  );
};

export default Webhooks;
