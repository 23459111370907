import {
  Datagrid,
  DateField,
  List,
  NumberField,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
  TextInput,
} from 'react-admin';
import { JsonField } from 'react-admin-json-view';
import { AdminPagination } from '../AdminLayout';

const orderFilters = [
  <NumberInput label="Id" source="id" alwaysOn />,
];

export const OrderList = () => (
  <List
    filters={orderFilters}
    sort={{ field: 'id', order: 'DESC' }}
    pagination={<AdminPagination />}
  >
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="internalCode" />
      <NumberField source="ClientOrderId" />
      <TextField source="status" />
      <TextField source="incidenceReason" />
      <TextField source="pat" />
      <TextField source="shippingType" />
      <TextField source="notFoundProducts" sortable={false} />
      <ReferenceField source="LocalId" reference="locals">
        <TextField source="name" />
      </ReferenceField>
      <NumberField source="chargeRatePercentage" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </Datagrid>
  </List>
);
