import {
  BooleanInput,
  Create,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';

export const ProductCreate = () => (
  <Create>
    <SimpleForm>
      <TextInput source="sku" />
      <TextInput source="rawSku" />
      <TextInput source="name" />
      <BooleanInput source="isService" />
      <BooleanInput source="hasModifiers" />
      <NumberInput source="pvp" />
      <ReferenceInput source="ClientId" reference="clients" perPage={10000}>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <NumberInput source="safetyStock" />
      <NumberInput source="expirationAlert" />
      <TextInput source="barcode" />
      <TextInput source="imageUrl" />
      <BooleanInput source="isPack" />
      <BooleanInput source="isWeighted" />
      <BooleanInput source="isFamily" />
      <TextInput source="familySku" />
      <SelectInput
        source="storageType"
        choices={[
          { id: 'dry', name: 'Dry' },
          { id: 'air-conditioned', name: 'Air Conditioned' },
          { id: 'refrigerated', name: 'Refrigerated' },
          { id: 'frozen', name: 'Frozen' },
        ]}
      />
      <BooleanInput source="needsLotTracking" />
      <BooleanInput source="ageVerification" />
      <BooleanInput source="activated" defaultValue={true} />
      <NumberInput source="weight" defaultValue={2750} />
      <NumberInput source="length" defaultValue={37}/>
      <NumberInput source="depth" defaultValue={18}/>
      <NumberInput source="height" defaultValue={30}/>
      <NumberInput source="unitsPerBox" />
      <NumberInput source="boxesPerPallet" />

    </SimpleForm>
  </Create>
);
