import { Paper } from '@mui/material';
import { ChangeEvent, Dispatch, useContext, useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import {
  IClient,
  ICommune,
  ICustomChangeEvent,
  ILocal,
  IAddress,
  IPointOfSale,
  ICountry,
  IOrderType,
  ICourier,
  IClientOrder,
} from '../../interfaces';
import { GlobalContext } from '../../store';
import { capitalize, formatDate } from '../../lib/utils';
import CustomInputField from './CustomInputField';
import { ISelectOptions } from './CustomSelectInput';
import { IRegion } from '../../interfaces/IRegion';
import { shippingTypeAvailableCouriers } from '../../lib/orders';
import { cancelReasonTranslation } from '../../lib/clientOrders';

const clientOrderInputKeys: { [key: string]: keyof (IClientOrder & IAddress) } = {
  ClienteInput: 'ClientId',
  TiendaInput: 'PointOfSaleId',
  TipoOrdenInput: 'OrderTypeId',
  CodeInput: 'code',
  'Plat.CodeInput': 'platformCode',
  NombreInput: 'customerName',
  TelefonoInput: 'customerPhone',
  EmailInput: 'customerEmail',
  TipoInput: 'shippingType',
  Direccion1Input: 'address1',
  Direccion2Input: 'address2',
  ComunaInput: 'CommuneId',
  Input: 'note',
  PrioridadInput: 'pickingPriority',
  CourierInput: 'CourierId',
};

type ContextType = { local: ILocal };

interface ClientOrderDataProps {
  clientOrder: Partial<IClientOrder>;
  setClientOrderData: (clientOrderData: Partial<IClientOrder>) => void;
  client: IClient | null;
  setClient: Dispatch<React.SetStateAction<IClient | null>>;
  editMode: boolean;
  children?: JSX.Element | JSX.Element[];
  operation: 'create' | 'edit';
  onInputChange?: (
    event: ChangeEvent<HTMLInputElement> | ICustomChangeEvent
  ) => void;
  setGlobalDisabled?: Dispatch<React.SetStateAction<boolean>>;
  globalDisabled?: boolean;
  destinationLocal?: ILocal | null;
  setDestinationLocal?: Dispatch<React.SetStateAction<ILocal | null>>;
  selectedOrderType?: IOrderType | null;
  setSelectedOrderType?: Dispatch<React.SetStateAction<IOrderType | null>>;
  isInternalOrder: boolean;
}

const ClientOrderData = (props: ClientOrderDataProps) => {
  const { api } = useContext(GlobalContext);
  const {
    clientOrder,
    setClientOrderData,
    client,
    setClient,
    destinationLocal,
    setDestinationLocal,
    editMode,
    children,
    operation,
    onInputChange = () => undefined,
    globalDisabled = false,
    setGlobalDisabled,
    selectedOrderType = null,
    setSelectedOrderType = () => null,
    isInternalOrder,
  } = props;
  const { local } = useOutletContext<ContextType>();
  const inputsDisabled = operation === 'edit';
  const [pointOfSale, setPointOfSale] = useState<IPointOfSale | null>(null);
  const [courier, setCourier] = useState<ICourier | null>(null);
  const [country, setCountry] = useState<ICountry | null>(null);
  const [region, setRegion] = useState<IRegion | null>(null);
  const [commune, setCommune] = useState<ICommune | null>(null);
  const shippingType = clientOrder.shippingType;
  const [disabledRegions, setDisabledRegions] = useState<boolean>(true);
  const [disabledCommunes, setDisabledCommunes] = useState<boolean>(true);
  const regions = country?.Regions || [];
  const communes = region?.Communes || [];
  const [selectedClientId, setSelectedClientId] = useState<number | null>(null);

  async function handleInputChange(
    event: ChangeEvent<HTMLInputElement> | ICustomChangeEvent
  ) {
    const dataToSet = {
      [clientOrderInputKeys[event.target.id]]: event.target.value || null,
    };
    if (event.target.id === 'TipoInput') {
      // set commune if shipping type is not delivery
      setCourier(null);
      const selectedShippingType = String(event.target.value);
      if (
        selectedShippingType === 'pickup' ||
        selectedShippingType === 'shopper'
      ) {
        delete clientOrder.shippingAddress;
      }
      
      setClientOrderData(dataToSet);
    } else if (event.target.id === 'ComunaInput') {
      // set commune with commune id (specific case)
      const selectedCommune = (event as ICustomChangeEvent).target
        .item as ICommune;
      setClientOrderData({
        shippingAddress: {
          ...clientOrder.shippingAddress,
          commune: selectedCommune?.name,
        },
        CommuneId: selectedCommune?.id,
      });
      setCommune(selectedCommune as ICommune);
    } else if (event.target.id.includes('CourierInput')) {
      const selectedCourier = (event as ICustomChangeEvent).target.item as ICourier;
      setClientOrderData({
        CourierId: selectedCourier?.id,
      });
      setCourier(selectedCourier as ICourier);
    } else if (event.target.id.includes('Direccion')) {
      setClientOrderData({
        shippingAddress: {
          ...clientOrder.shippingAddress,
          ...dataToSet,
        },
      });
    } else if (event.target.id.includes('País')) {
      const selectedCountry = (event as ICustomChangeEvent).target
        .item as ICountry;
      setCountry(selectedCountry);
      setCommune(null);
      setRegion(null);
      if (selectedCountry) {
        setDisabledRegions(false);
      } else {
        setDisabledRegions(true);
        setDisabledCommunes(true);
      }
    } else if (event.target.id.includes('Región')) {
      const selectedRegion = (event as ICustomChangeEvent).target
        .item as IRegion;
      setRegion(selectedRegion);
      setCommune(null);
      selectedRegion ? setDisabledCommunes(false) : setDisabledCommunes(true);
    } else if (event.target.id === 'ClienteInput') {
      setSelectedClientId(Number(dataToSet.ClientId));
      setClientOrderData(dataToSet);
    } else if (event.target.id === 'TipoOrdenInput' && setSelectedOrderType) {
      setSelectedOrderType((event as ICustomChangeEvent).target.item as IOrderType);
      setClientOrderData(dataToSet);
    } else {
      // general case
      setClientOrderData(dataToSet);
    }
    // trigger event
    onInputChange(event);
  }

  async function getClientPOS(): Promise<IPointOfSale[]> {
    if (client) {
      return api.getPOS(client.id);
    }
    return [];
  }

  async function getLocalById(id: number | string): Promise<ILocal> {
    const locals = await api.getLocals();
    return locals.find((local) => local.id === id) as ILocal;
  }

  function selectOptions(): ISelectOptions {
    if (selectedOrderType?.name === 'WO') {
      return [
        {
          value: 'pickup',
          disabled: false,
        },
        {
          value: 'same-day',
          disabled: false,
        },
      ];
    }
    return ['pickup', 'express', 'same-day', 'courier', 'shopper']
      .map((x) => ({ value: x, disabled: false }))
  }
  useEffect(() => {
    if (editMode) {
      setCountry(null);
      setRegion(null);
      setCommune(null);
    }
  }, [editMode]);


  // Define global disable according to required data by order type
  useEffect(() => {
    if (!selectedOrderType || !setGlobalDisabled) return;
    if (selectedOrderType.name === 'TO' && selectedClientId) {
      setGlobalDisabled(false);
    } else if (selectedOrderType.name === 'WO' && selectedClientId) {
      setGlobalDisabled(false);
    } else if (selectedClientId) {
      setGlobalDisabled(false);
    } else {
      setGlobalDisabled(true);
    }
  }, [selectedOrderType, selectedClientId]);

  // Set platform code and code
  useEffect(() => {
    // reset values when changes orderType
    const resetData = {
      shippingType: '',
      date: undefined,
      shippingCost: 0,
      globalDiscount: 0,
      shippingDiscount: 0,
      total: 0,
      OrderDetails: [],
      customerEmail: '',
      customerName: '',
      customerPhone: '',
    };
    setCommune(null);
    setRegion(null);
    setCourier(null);

    if ((clientOrder.code && clientOrder.platformCode) || !selectedOrderType) return;
    const prefix = 'NOMAD-';
    setClientOrderData({ ...resetData, code: prefix, platformCode: prefix });
  }, [selectedOrderType]);

  return (
    <div id="OrderData">
      <Paper className="Paper" elevation={2}>
        {operation === 'create' ? (
          <h2>Crear pedido</h2>
        ) : (
          <h2>{clientOrder.code || clientOrder.id}</h2>
        )}
        <CustomInputField
          disabled
          label="MFC"
          value={capitalize(clientOrder.Local?.name || local.name)}
          editMode={false}
          onChange={handleInputChange}
        />
        {operation === 'edit' && (
          <>
            <CustomInputField
              disabled
              label="Fecha"
              value={formatDate(clientOrder.date as string)}
              editMode={false}
              onChange={handleInputChange}
              upperCase
            />
            <CustomInputField
              disabled
              label="Status"
              value={clientOrder.status || ''}
              editMode={false}
              onChange={handleInputChange}
              upperCase
            />
            {(clientOrder.cancelReason) && (
              <CustomInputField
                disabled
                label="Razón Cancelación"
                value={cancelReasonTranslation[clientOrder.cancelReason]}
                editMode={false}
                onChange={handleInputChange}
              />
            )}
          </>
        )}
        <CustomInputField
          disabled={inputsDisabled}
          label="Tipo Orden"
          dataKey="name"
          editMode={editMode}
          onChange={handleInputChange}
          inputType="autocomplete"
          autocompleteDefaultValue={selectedOrderType}
          value={selectedOrderType?.name}
          fetchData={async () => api.getOrderTypes({ filter: { name: ['B2C', 'B2B', 'TO', 'WO', 'Retailer'] } })}
          dataFieldProps={{
            data: selectedOrderType,
            getData: () => api.getOrderTypeById(clientOrder.OrderTypeId),
            setData: setSelectedOrderType,
          }}
        />
        <CustomInputField
          disabled={inputsDisabled}
          label="Cliente"
          dataKey="name"
          editMode={editMode}
          onChange={handleInputChange}
          inputType="autocomplete"
          autocompleteDefaultValue={client}
          value={client?.name}
          fetchData={async () => api.getClients({ active: true })}
          dataFieldProps={{
            data: client,
            getData: async () => api.getClientById(clientOrder.ClientId),
            setData: setClient,
          }}
        />
        { operation === 'create' && (<CustomInputField
          inputType="checkbox"
          label="Prioridad"
          value={String(clientOrder.pickingPriority)}
          editMode={editMode}
          onChange={handleInputChange}
          disabled={globalDisabled}
        />)}
        {selectedOrderType && selectedOrderType.name === 'TO' && operation === 'create' && (
          <>
            <h3>Destino</h3>
            <CustomInputField
              disabled={inputsDisabled || globalDisabled}
              label="Destino"
              dataKey="name"
              editMode={editMode}
              onChange={handleInputChange}
              inputType="autocomplete"
              autocompleteDefaultValue={destinationLocal as ILocal}
              fetchData={async () =>
                (await api.getLocals({ filter: { active: true } })).filter((x) => x.id !== local.id)
              }
              dataFieldProps={{
                data: destinationLocal as ILocal,
                getData: async () => getLocalById(destinationLocal?.id || ''),
                setData: setDestinationLocal as Dispatch<React.SetStateAction<ILocal | null>>,
              }}
            />
          </>
        )}

        {selectedOrderType && !isInternalOrder && (
          <>
            <h3>Tienda</h3>
            <CustomInputField
              disabled={globalDisabled}
              label="Tienda"
              dataKey="name"
              editMode={editMode}
              onChange={handleInputChange}
              inputType="autocomplete"
              autocompleteDefaultValue={pointOfSale}
              fetchData={getClientPOS}
              dataFieldProps={{
                data: pointOfSale,
                getData: () => api.getPointOfSaleById(clientOrder.PointOfSaleId),
                setData: setPointOfSale,
              }}
            />
          </>
        )}

        {selectedOrderType && selectedOrderType.name !== 'TO' && (
          <>
            <h3>Consumidor Final</h3>
            <CustomInputField
              label="Nombre"
              value={clientOrder.customerName}
              editMode={editMode}
              onChange={handleInputChange}
              disabled={globalDisabled}
            />
            <CustomInputField
              label="Telefono"
              value={clientOrder.customerPhone}
              editMode={editMode}
              onChange={handleInputChange}
              disabled={globalDisabled}
            />
            <CustomInputField
              label="Email"
              value={clientOrder.customerEmail}
              editMode={editMode}
              onChange={handleInputChange}
              disabled={globalDisabled}
            />
          </>
        )}
        {(operation === 'edit' || (operation === 'create' && selectedOrderType && selectedOrderType.name !== 'TO')) && (
          <>
            <h3>Envío</h3>
            <CustomInputField
              disabled={inputsDisabled || globalDisabled}
              label="Tipo"
              inputType="select"
              value={clientOrder.shippingType || ''}
              editMode={editMode}
              onChange={handleInputChange}
              selectOptions={selectOptions()}
            />
            {operation === 'create' && selectedOrderType && ['B2C', 'B2B'].includes(selectedOrderType.name) && shippingType && (
              <CustomInputField
                disabled={inputsDisabled}
                label="Courier"
                dataKey="name"
                editMode={editMode}
                onChange={handleInputChange}
                inputType="autocomplete"
                autocompleteDefaultValue={courier}
                value={courier?.name}
                fetchData={async () => api.getCouriers({name: shippingTypeAvailableCouriers[shippingType]})}
                dataFieldProps={{
                  data: courier,
                  getData: () => api.getCourierById(undefined),
                  setData: setCourier,
                }}
              />
            )}
            {((shippingType !== 'pickup' && shippingType !== 'shopper') || clientOrder.shippingAddress) && (
              <>
                <CustomInputField
                  label="Direccion 1"
                  value={clientOrder.shippingAddress?.address1 || ''}
                  editMode={editMode}
                  onChange={handleInputChange}
                  disabled={globalDisabled}
                />
                <CustomInputField
                  label="Direccion 2"
                  value={clientOrder.shippingAddress?.address2 || ''}
                  editMode={editMode}
                  onChange={handleInputChange}
                  disabled={globalDisabled}
                />
                {editMode && (
                  <>
                    <CustomInputField
                      label="País"
                      dataKey="name"
                      editMode={editMode}
                      onChange={handleInputChange}
                      inputType="autocomplete"
                      autocompleteDefaultValue={country}
                      value={country?.name}
                      fetchData={async () => api.getCountries()}
                      dataFieldProps={{
                        data: null,
                        getData: null as unknown as () => Promise<ICountry>,
                        setData: setCountry,
                      }}
                      disabled={globalDisabled}
                    />
                    <CustomInputField
                      label="Región"
                      dataKey="name"
                      editMode={editMode}
                      onChange={handleInputChange}
                      inputType="autocomplete"
                      autocompleteDefaultValue={region}
                      fetchData={async () => regions}
                      dataFieldProps={{
                        data: null,
                        getData: null as unknown as () => Promise<IRegion>,
                        setData: setRegion,
                      }}
                      disabled={disabledRegions}
                    />
                  </>
                )}

                <CustomInputField
                  label="Comuna"
                  dataKey="name"
                  editMode={editMode}
                  onChange={handleInputChange}
                  inputType="autocomplete"
                  autocompleteDefaultValue={commune}
                  fetchData={async () => communes}
                  dataFieldProps={{
                    data: editMode ? null : commune,
                    getData: () => api.getCommuneById(clientOrder.CommuneId),
                    setData: setCommune,
                  }}
                  disabled={disabledCommunes}
                />
              </>
            )}
          </>
        )}
        <h3>Nota</h3>
        <CustomInputField
          inputType="textarea"
          label=""
          editMode={editMode}
          onChange={handleInputChange}
          value={clientOrder.note}
          disabled={globalDisabled}
        />
        {children}
      </Paper>
    </div>
  );
};

export default ClientOrderData;
