import { IClientOrder, IOrderDetail } from '../../interfaces';
import { formatPrice } from '../../lib/utils';
import NumberInputField from './NumberInputField';

const summaryRows: Array<{ label: string; field: keyof IClientOrder }> = [
  {
    label: 'Costo despacho',
    field: 'shippingCost',
  },
  {
    label: 'Descuento despacho',
    field: 'shippingDiscount',
  },
  {
    label: 'Descuento global',
    field: 'globalDiscount',
  },
];

interface OrderSummaryProps {
  clientOrder: Partial<IClientOrder>;
  setClientOrderData: (data: Partial<IClientOrder>) => void;
  editMode?: boolean;
  globalDisabled?: boolean;
}

const OrderSummary = (props: OrderSummaryProps) => {
  const {
    clientOrder,
    setClientOrderData,
    editMode = false,
    globalDisabled = false,
  } = props;

  function handleChange(id: string, value: number) {
    setClientOrderData({ [id]: value });
  }

  function getOrderTotal() {
    let orderTotal = 0;
    let orderDetails: IOrderDetail[] = [];
    if (clientOrder.OrderDetails?.length) {
      orderDetails = clientOrder.OrderDetails;
    } else if (clientOrder.Orders?.length && clientOrder.Orders[0].OrderDetails.length) {
      orderDetails = clientOrder.Orders[0].OrderDetails;
    } else {
      return 0;
    }

    for (let idx = 0; idx < orderDetails.length; idx++) {
      const element = orderDetails[idx];
      const get = (id: keyof IOrderDetail) => {
        return Number(element[id]);
      };
      if (get('price') === null) {
        return 0;
      }
      orderTotal += get('amount') * get('price') - get('discount');
    }
    orderTotal += clientOrder.shippingCost || 0;
    orderTotal -= clientOrder.shippingDiscount || 0;
    orderTotal -= clientOrder.globalDiscount || 0;
    return orderTotal;
  }

  return (
    <div className="Border">
      <div id="OrderSummary">
        {summaryRows.map((row, idx) => (
          <div className="Row Start" key={idx}>
            <div className="SummaryLabel Flex6">{row.label}</div>
            <div className="Flex1">
              <NumberInputField
                id={row.field}
                editMode={editMode}
                defaultValue={Number(clientOrder[row.field])}
                onChange={handleChange}
                money
                disabled={globalDisabled}
              />
            </div>
          </div>
        ))}
        <div className="Row Start">
          <div className="SummaryLabel Flex6">Total</div>
          <div className="Flex1">{formatPrice(getOrderTotal())}</div>
        </div>
      </div>
    </div>
  );
};

export default OrderSummary;
