import {
  Outlet,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import {
  IClient,
  IClientOrder,
  IDocument,
} from '../../interfaces';
import {
  isEqualToClientOrder,
} from '../../lib/clientOrders';
import { confirm } from '../../lib/utils';
import EditButtons from '../../components/EditButtons';
import { Button } from '@mui/material';
import Back from '../../components/Back';
import { GlobalContext } from '../../store';
import { alert } from '../../lib/utils';
import PageLoader from '../../components/PageLoader';
import ClientOrderData from '../../components/Order/ClientOrderData';
import OrderBlocks from '../../components/Order/OrderBlocks';
import OrderSummary from '../../components/Order/OrderSummary';
import OrderDetails from '../../components/Order/OrderDetails';
import DowloadDocumentMenu from '../../components/Order/DownloadDocumentMenu';
  
const ClientOrderShow = () => {
  const {
    api,
    user,
    context: { startLoading, finishLoading },
  } = useContext(GlobalContext);
  const { clientOrderId } = useParams();
  const [clientOrder, setClientOrder] = useState<IClientOrder>();
  const [updatedClientOrder, setUpdatedClientOrder] = useState<IClientOrder>();
  const navigate = useNavigate();
  const [client, setClient] = useState<IClient | null>(null);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [activeClient, setActiveClient] = useState<boolean>(false);
  const printableDocuments = ['invoice', 'bill', 'creditNote', 'salesNote', 'dispatchGuide', 'dispatchLetter', 'purchaseOrder', 'deliveryAppointment', 'generatedLabelsExcel'];

  useEffect(() => {
    (async () => {
      const { clientOrders } = await api.getClientOrders({
        filter: { ids: [Number(clientOrderId)] },
      });
      const clientOrder = clientOrders[0];
      if (clientOrder === null) {
        navigate('../');
      }
      if (clientOrder) {
        setClientOrder(clientOrder);
        setUpdatedClientOrder(clientOrder);
      }
    })();
  }, []);

  useEffect(() => {
    if (!clientOrder || !clientOrder.ClientId) return;
    (async () => {
      const client = await api.getClientById(clientOrder.ClientId);
      setActiveClient(client.active);
    })();
  }, [clientOrder]);

  function reset() {
    setEditMode(false);
    window.location.reload();
  }


  function showCancelClientOrderButton(clientOrder: IClientOrder) {
    if  ((clientOrder.status === 'cancelled') || (
      (clientOrder.status === 'completed') && !clientOrder.Orders
      .some((order) => order.incidenceReason
      && order.incidenceReason !== 'pickup-expired'))) {
      return false;
    } 
    return true;
  }


  if (!clientOrder || !updatedClientOrder) {
    return <PageLoader />;
  }

  const handleClientOrderSave = async () => {
    try {
      startLoading();
      // Save order data (left panel)
      if (!isEqualToClientOrder(updatedClientOrder, clientOrder)) {
        // make request
        const { Orders, ...newClientOrderData } = updatedClientOrder;
        await api.updateClientOrder(clientOrder.id, newClientOrderData);
      }

      setClientOrder({ ...clientOrder, ...updatedClientOrder });

      reset();
    } catch (error) {
      if (error instanceof Error) {
        alert('error', error.message);
      }
    }
    finishLoading();
  };

  const handleGenerateInvoice = async () => {
    try {
      let confirmed = true;
      confirmed = await confirm('Vas a crear/obtener el documento contable.');
      if (confirmed) {
        window.open(`${clientOrder.invoiceSignedUrl}&checkFulfilled=true`, '_blank');
      }
    } catch (error) {
      if (error instanceof Error) {
        alert('error', error.message);
      }
    }
  };


  function handleUpdatedClientOrderDataChange(clientOrderData: Partial<IClientOrder>) {
    setUpdatedClientOrder((clientOrder) => {
      if (clientOrder) {
        return {
          ...clientOrder,
          ...clientOrderData,
        };
      }
      return clientOrder;
    });
  }

  const documentOptions = () => {
    if (!clientOrder) return [];
    const documentOptions = printableDocuments.flatMap((documentType) => {
      const doc = clientOrder.Documents.find(
        (clientDocument: IDocument) => 
          clientDocument.type === documentType && 
          clientDocument.url && 
          clientDocument.url?.includes('http')
      );
      
      return doc ? [doc] : [];
    });
    return documentOptions;
  }


  return (
    <>
      <Back />
      <div className="OrderContainer">
        <ClientOrderData
          operation="edit"
          clientOrder={updatedClientOrder}
          setClientOrderData={handleUpdatedClientOrderDataChange}
          client={client}
          setClient={setClient}
          editMode={editMode}
          isInternalOrder={['TO', 'WO'].includes(clientOrder.OrderType.name)}
          selectedOrderType={clientOrder.OrderType}
        >
          <div className='Column '>
          {documentOptions().length > 0 && (
            <DowloadDocumentMenu documentOptions={documentOptions()} />
          )}
          {activeClient && (
            <>
              <Button
                variant="contained"
                onClick={handleGenerateInvoice}
                disabled={clientOrder.Documents.length !== 0}
                color='primary'
                sx={clientOrder.OrderType.name === 'B2B' ? { fontWeight: 'bold' } : {}}
              >
                Generar Documento
              </Button>
            {showCancelClientOrderButton(clientOrder) && (
                <Button
                  variant="contained"
                  color="warning"
                  onClick={() => navigate('cancel')}
                  disabled={clientOrder.status === 'cancelled'}
                >
                  Cancelar Pedido
                </Button>
            )}
          </>
          )}
        </div>
        </ClientOrderData>
        <div id="OrderDetails">
        <OrderBlocks clientOrder={updatedClientOrder}/>
          {clientOrder.Orders.length && (
            <OrderDetails
              order={clientOrder.Orders[0]}
              editMode={false}
              onUpdateOrderDetail={()=> {
                throw new Error('Function not implemented.');
              }} 
              onDeleteOrderDetail={()=> {
                throw new Error('Function not implemented.');
              }} 
              onAddOrderDetail={()=> {
                throw new Error('Function not implemented.');
              }}
              pointOfSaleId={null}
              globalDisabled={true}>
                <OrderSummary
              clientOrder={updatedClientOrder}
              setClientOrderData={handleUpdatedClientOrderDataChange}
              editMode={editMode}
            />
            </OrderDetails>

          )}
          {activeClient && (
            <EditButtons
              saveDisabled={
                isEqualToClientOrder(updatedClientOrder, clientOrder)
              }
              editDisabled={
                ['completed', 'cancelled'].includes(clientOrder.status) &&
                user.role !== 'admin'
              }
              editMode={editMode}
              onCancel={reset}
              onEdit={() => setEditMode(true)}
              onSave={handleClientOrderSave}
            />
          )}
          <Outlet context={{ clientOrder }} />
        </div>
      </div>
    </>
  );
};

export default ClientOrderShow;
