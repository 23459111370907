import {
  IOrder,
  IProduct,
} from '../../interfaces';
import PickingError from './PickingError';
import { IPickingError } from '../../interfaces/IPickingError';
import ProductSearch from './ProductSearch';
import generateKey from '../../helpers/generateKey';
import { alert } from '../../lib/utils';

interface PickingErrorProps {
  order: IOrder;
  pickingErrors: IPickingError[];
  pointOfSaleId: number | null;
  onUpdatePickingError: (
    key: string,
    id: string | number | symbol,
    value: number | string
  ) => void;
  onDeletePickingError: (item: IPickingError) => void;
  onAddPickingError: (item: IPickingError) => void;
  editMode?: boolean;
  globalDisabled?: boolean;
}

const PickingErrors = ({
  order,
  pickingErrors,
  pointOfSaleId,
  onAddPickingError,
  onUpdatePickingError,
  onDeletePickingError,
  globalDisabled = false,
  editMode = false,
}: PickingErrorProps) => {

  function handleItemChange(
    key: string,
    id: string | number | symbol,
    value: number | string
  ) {
    onUpdatePickingError(key, id, value);
  }

  function isValidPickingError(
    newPickingError: Partial<IPickingError>,
    originalPickingError?: IPickingError,
  ) {
    if (!newPickingError.type || !newPickingError.amount) {
      return false;
    }
    const previousPickingErrors = originalPickingError ? pickingErrors
      .filter((item) => item.ReactComponentId !== originalPickingError.ReactComponentId) : pickingErrors;

    const sameProductPickingErrors = previousPickingErrors.filter((item) => item.ProductId === newPickingError.ProductId);
    if (sameProductPickingErrors.find((item) => item.type === newPickingError.type)){
      return false;
    }
    const types = sameProductPickingErrors.map((item) => item.type);

    types.push(newPickingError.type);
    if (
      (types.includes('extra') && types.includes('missing')) ||
      (types.includes('extra') && types.includes('broken')) ||
      (types.includes('extra-broken') && types.includes('missing'))
    ) {
    return false;
    }
    const totalProductAmount = order.OrderDetails?.find((x) => x.ProductId === newPickingError.ProductId)?.amount;

    if (!totalProductAmount) {
      return ['extra', 'extra-broken'].includes(newPickingError.type);
    }

    const allProductErrors = [...sameProductPickingErrors, newPickingError];
    const missingOrBrokenErrors = allProductErrors.filter(error => 
      error.type === 'missing' || error.type === 'broken'
    );

    const totalErrorAmount = missingOrBrokenErrors.reduce((sum, error) => sum + (error.amount || 0), 0);

    return totalErrorAmount <= totalProductAmount;
  }

  async function handleAddPickingError(newValue: IProduct) {
    try {
      const types = ['missing', 'broken', 'extra', 'extra-broken'];
   
      for (const type of types) {
        const pickingError: IPickingError = {
          ProductId: newValue.id,
          amount: 1,
          type: type as 'missing' | 'broken' | 'extra' | 'extra-broken',
          ReactComponentId: generateKey(newValue.id),
          OrderId: order.id,
        };
   
        if (isValidPickingError(pickingError)) {
          onAddPickingError(pickingError);
          return;
        }
      }
   
      throw new Error('No valid picking error type could be added');
    } catch (error) {
      if (error instanceof Error) {
        alert('error', error.message);
      }
    }
   }

  return (
    <>
      <div id="OrderDetails">
        {editMode && (
          <div className="Row Between">
            <h3>Productos</h3>
            <ProductSearch
              pointOfSaleId={pointOfSaleId}
              onAddItem={handleAddPickingError}
              disabled={globalDisabled}
              withStock={false}
            />
          </div>
        )}
        {pickingErrors.length === 0 && (
          <div className="Row Start">
            <p className="Placeholder">
              Comienza agregando productos desde el buscador...
            </p>
          </div>
        )}
        {pickingErrors && (
          <div className="Row" id="OrderDetailsHeader">
            <div className="Flex2">Cantidad</div>
            <div className="Flex6">Producto</div>
            <div className="Flex2">Tipo</div>
            <div className="Flex1"></div>
          </div>
        )}
        {pickingErrors
          .map((item, idx) => (
            <PickingError
              key={idx}
              idx={item.ReactComponentId}
              item={item}
              onChange={handleItemChange}
              onDelete={onDeletePickingError}
              editMode={editMode}
              isValidPickingError={isValidPickingError}
            />
          ))}
      </div>
    </>
  );
};

export default PickingErrors;
