import * as React from 'react';
import { Menu, MenuItem, Button } from '@mui/material';
import { IDocument } from '../../interfaces';

interface DownloadDocumentMenuProps {
  documentOptions: IDocument[];
}


const DownloadDocumentMenu = (props: DownloadDocumentMenuProps) => {
  const { documentOptions } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getButtonText = (doc: IDocument) => {
    if (doc.type === 'creditNote') {
      return 'Nota de crédito';
    } if (doc.type === 'invoice') {
      return 'Factura';
    } if (['bill', 'salesNote'].includes(doc.type)) {
      return 'Boleta';
    } if (doc.type === 'dispatchGuide') {
      return 'Guía de despacho';
    } if (doc.type === 'purchaseOrder') {
      return 'Orden de compra';
    } if (doc.type === 'deliveryAppointment') {
      return 'Cita de entrega';
    } if (doc.type === 'generatedLabelsExcel') {
      return 'Excel de etiquetas';
    } if (doc.type === 'dispatchLetter') {
      return 'Carta de despacho';
    }
    return doc.type;
  };
  return (
    <div>
      <Button
        variant="text"
        color="primary"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        Descargar Documento
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {documentOptions.map((doc) => (
          <MenuItem onClick={() => window.open(doc.url, '_blank')}>
            {getButtonText(doc)}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
export default DownloadDocumentMenu;