import {
  BooleanField,
  Datagrid,
  DateField,
  List,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
} from 'react-admin';
import { AdminPagination } from '../AdminLayout';

const storeFilters = [
  <ReferenceInput
    source="ClientId"
    reference="clients"
    sort={{ field: 'name', order: 'ASC' }}
    perPage={1000}
    alwaysOn
  >
    <SelectInput optionText="name" />
  </ReferenceInput>,

  <ReferenceInput
    source="IntegrationId"
    reference="integrations"
    sort={{ field: 'name', order: 'ASC' }}
    perPage={1000}
    alwaysOn
  >
    <SelectInput optionText="name" />
  </ReferenceInput>,
];

export const PointOfSaleList = () => (
  <List filters={storeFilters} pagination={<AdminPagination />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <ReferenceField source="ClientId" reference="clients">
        <TextField source="name" />
      </ReferenceField>

      <ReferenceField source="IntegrationId" reference="integrations">
        <TextField source="name" />
      </ReferenceField>

      <ReferenceField source="CountryId" reference="countries">
        <TextField source="name" />
      </ReferenceField>

      <BooleanField source="hasExpressTab" />

      <ReferenceField source="principalLocalId" reference="locals">
        <TextField source="name" />
      </ReferenceField>

      <ReferenceField source="OrderTypeId" reference="order-types">
        <TextField source="name" />
      </ReferenceField>

      <DateField source="activationDate" showTime/>

      <DateField source="createdAt" />

      <DateField source="updatedAt" />
    </Datagrid>
  </List>
);
