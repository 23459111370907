import { useState } from 'react';
import {
  Create,
  DateInput,
  FormDataConsumer,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from 'react-admin';
import { JsonInput } from 'react-admin-json-view';

export const ClientOrderCreate = () => {
  const [countryId, setCountryId] = useState<number | null>(null);
  const [regionId, setRegionId] = useState<number | null>(null);
  const [clientId, setClientId] = useState<number | null>(null);
  const [orderTypeId, setOrderTypeId] = useState<number | null>(null);
  const [, setPointOfSaleId] = useState<number | null>(null);

  return (
    <Create>
      <SimpleForm>
        <TextInput source="customerName" />
        <NumberInput source="OriginalOrderId" />
        <DateInput source="date" />
        <TextInput source="code" />
        <TextInput source="platformCode" />
        <TextInput source="customerEmail" />
        <TextInput source="note" multiline />
        <JsonInput
          source="shippingAddress"
          jsonString={false} // Set to true if the value is a string, default: false
          defaultValue={{
            address1: '',
            address2: '',
            commune: '',
          }}
          reactJsonOptions={{
            // Props passed to react-json-view
            name: null,
            collapsed: true,
            enableClipboard: false,
            displayDataTypes: true,
          }}
        />
        <TextInput source="commune" />
        <TextInput source="ticketInfo" />
        <NumberInput source="globalDiscount" />
        <TextInput source="shippingType" />
        <NumberInput source="shippingDiscount" />
        <TextInput source="status" />
        <TextInput source="customerPhone" />
        <TextInput source="shippingCost" />
        <ReferenceInput source="ClientId" reference="clients" perPage={10000}>
          <SelectInput
            onChange={(e) => {
              setClientId(e.target.value);
            }}
            optionText="name"
          />
        </ReferenceInput>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            !!formData.ClientId && (
              <>
                {!!clientId && (
                  <ReferenceInput
                    source="PointOfSaleId"
                    reference="pos"
                    filter={{ ClientId: clientId }}
                    perPage={10000}
                    {...rest}
                  >
                    <SelectInput
                      onChange={(e) => {
                        setPointOfSaleId(e.target.value);
                      }}
                      optionText="name"
                    />
                  </ReferenceInput>
                )}
              </>
            )
          }
        </FormDataConsumer>
        <ReferenceInput
          source="OrderTypeId"
          reference="order-types"
          perPage={1000}
          filter={{ id: [1,2,3,4] }}
        >
          <SelectInput
            onChange={(e) => {
              setOrderTypeId(e.target.value);
            }}
            optionText="name"
          />
        </ReferenceInput>
        <ReferenceInput source="LocalId" reference="locals" perPage={10000} filter={{ active: true }}>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
          source="CountryId"
          reference="countries"
          sort={{ field: 'name', order: 'ASC' }}
          perPage={100}
        >
          <SelectInput
            onChange={(e) => {
              setCountryId(e.target.value);
              setRegionId(null);
            }}
            optionText="name"
          />
        </ReferenceInput>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.CountryId !== null &&
            formData.CountryId !== undefined && (
              <>
                {countryId !== null && (
                  <ReferenceInput
                    source="RegionId"
                    reference="regions"
                    sort={{ field: 'name', order: 'ASC' }}
                    filter={{ CountryId: countryId }}
                    perPage={10000}
                    {...rest}
                  >
                    <SelectInput
                      onChange={(e) => {
                        setRegionId(e.target.value);
                      }}
                      optionText="name"
                    />
                  </ReferenceInput>
                )}
                {formData.RegionId !== null &&
                  formData.RegionId !== undefined &&
                  regionId && (
                    <ReferenceInput
                      source="CommuneId"
                      reference="communes"
                      filter={{ RegionId: regionId }}
                      sort={{ field: 'name', order: 'ASC' }}
                      perPage={10000}
                      {...rest}
                    >
                      <SelectInput optionText="name" />
                    </ReferenceInput>
                  )}
              </>
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            !!formData.ClientId && !!formData.OrderTypeId && (
              <>
                {!!clientId && !!orderTypeId && (
                  <ReferenceInput
                    source="ChargeRateId"
                    reference="charge-rates"
                    filter={{ ClientId: clientId, OrderTypeId: orderTypeId }}
                    perPage={10000}
                    {...rest}
                  >
                    <SelectInput optionText="name" />
                  </ReferenceInput>
                )}
              </>
            )
          }
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
};
