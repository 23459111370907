import { Link, useLocation, useOutletContext } from 'react-router-dom';
import { ILocal } from '../interfaces';

type ContextType = { local: ILocal };

export default function LocalButtons() {
  const { local } = useOutletContext<ContextType>();
  const location = useLocation();

  function buttonBackground(name: string) {
    return location.pathname.includes(`/${name}`)
      ? 'BackgroundPrimary'
      : 'BackgroundInfo';
  }

  return (
    <div className="Row Around">
      <Link key={'client-orders'} to={`/local/${local.id}/client-orders`}>
        <div className={`Card Border Rounded ${buttonBackground('client-orders')}`}>
          <h2>Pedidos</h2>
        </div>
      </Link>
      <Link key={'product-locations'} to={`/local/${local.id}/product-locations`}>
        <div
          className={`Card Border Rounded ${buttonBackground(
            'product-locations'
          )}`}
        >
          <h2>Ubicaciones</h2>
        </div>
      </Link>
      <Link key={'losses'} to={`/local/${local.id}/losses`}>
        <div className={`Card Border Rounded ${buttonBackground('losses')}`}>
          <h2>Mermas</h2>
        </div>
      </Link>
    </div>
  );
}
