import { Link } from 'react-router-dom';
import {
    IClientOrder,
} from '../../interfaces';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { isActive } from '../../lib/shipments';

interface OrderBlocksProps {
  clientOrder: Partial<IClientOrder>;
}

const OrderBlocks = ({
  clientOrder,
}: OrderBlocksProps) => {
  const orders = clientOrder.Orders || [];

  const columnHeaders = ['Local', 'Código', 'Tipo Envío', 'Estado Orden', 'Estado Envío', 'Incidencia'];

  return (
    <>
    <div id="OrderDetails">
    <h2>Ordenes de bodega</h2>
     <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={'medium'}
          >
          <TableHead>
            <TableRow>
              <TableCell
                key={'intento'}
                align={'right'}
                padding={'normal'}
                sortDirection={false}
              >
                Intento
              </TableCell>
              {columnHeaders.map((column) =>
                <TableCell
                  key={column}
                  align={'left'}
                  padding={'normal'}
                  sortDirection={false}
                  >
                    {column}
                  </TableCell>
              )}
            </TableRow>
          </TableHead>
        <TableBody>
          {orders.map((row, index) => {
            const activeShipment = row.Shipments.filter((s) => isActive(s))[0];
            const shipmentStatus = activeShipment?.status || row.Shipments.length ? row.Shipments[0].status : ''
            const columnValues = [
              row.Local.name,
              row.internalCode,
              row.shippingType, 
              row.status, 
              shipmentStatus,
              row.incidenceReason,
            ]
            return (
              <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
                key={row.id}
                component={Link}
                to={`./orders/${row.id}`}
              >
                <TableCell component="th" scope="row" align="right">
                  {index + 1}
                </TableCell>
                {columnValues.map((value) =>
                  <TableCell component="th" scope="row" align="left">
                  {value}
                </TableCell>
                )}
              </TableRow>
            );
          })}
        </TableBody>
          </Table>
        </TableContainer>
        </div>
    </>
  );
};

export default OrderBlocks;