import { TextAreaField } from '@aws-amplify/ui-react';
import { Checkbox, TextField } from '@mui/material';
import { IData } from '../../interfaces';
import AutocompleteInput from './AutocompleteInput';
import CustomSelectInput, { ISelectOptions } from './CustomSelectInput';
import DataField from './DataField';

interface TextFieldProps<T> {
  label: string;
  editMode: boolean;
  onChange: (event: any, newValue?: T | null | any) => void;
  value?: string;
  upperCase?: boolean;
  disabled?: boolean;
  inputType?: 'text' | 'autocomplete' | 'select' | 'textarea' | 'checkbox';
  fetchData?: () => Promise<T[]>;
  dynamicFetchData?: (newInputValue: string) => Promise<T[]>;
  dataFieldProps?: {
    data: T | null;
    getData: () => Promise<T>;
    setData: (data: any) => void;
  };
  autocompleteDefaultValue?: T | null;
  dataKey?: keyof T;
  selectOptions?: ISelectOptions;
}

const CustomInputField = <T extends IData>({
  label,
  dataKey,
  value,
  editMode,
  onChange,
  upperCase = false,
  disabled = false,
  inputType = 'text',
  fetchData,
  dynamicFetchData,
  dataFieldProps,
  selectOptions,
  autocompleteDefaultValue,
}: TextFieldProps<T>) => {
  function renderValue() {
    switch (inputType) {
      case 'text':
      case 'select':
        if (value === undefined) {
          throw new Error(`Value of ${label} cannot be undefined`);
        }
        return <p>{upperCase ? value.toUpperCase() : value}</p>;
      case 'autocomplete':
        if (dataFieldProps === undefined) {
          throw new Error('dataFieldProps cannot be undefined');
        }
        if (dataKey === undefined) {
          throw new Error(`dataKey of ${label} cannot be undefined`);
        }
        return (
          <DataField
            data={dataFieldProps.data}
            dataKey={dataKey}
            getData={dataFieldProps.getData}
            setData={dataFieldProps.setData}
          />
        );
      case 'textarea':
        return (
          <>
            {value?.trim() ? (
              <p>{value}</p>
            ) : (
              <p className="Placeholder">Texto vacío</p>
            )}
          </>
        );
      case 'checkbox':
        if (value === undefined) {
          throw new Error(`Value of ${label} cannot be undefined`);
        }
        return <p>{value === 'true' ? 'SI' : 'NO'}</p>;
    }
  }

  function renderInput() {
    const normalLabel = label.replace(' ', '');
    switch (inputType) {
      case 'text':
        return (
          <TextField
            id={`${normalLabel}Input`}
            className="InputField"
            size="small"
            label={label}
            value={value}
            onChange={onChange}
            disabled={disabled}
          />
        );
      case 'autocomplete':
        if (fetchData === undefined) {
          throw new Error(`fetchData of ${label} cannot be undefined`);
        }
        if (dataKey === undefined) {
          throw new Error(`dataKey of ${label} cannot be undefined`);
        }
        return (
          <AutocompleteInput
            defaultValue={autocompleteDefaultValue}
            label={normalLabel}
            dataKey={dataKey}
            onChange={onChange}
            fetchData={fetchData}
            dynamicFetchData={dynamicFetchData}
            disabled={disabled}
          />
        );
      case 'select':
        if (!selectOptions) {
          throw new Error(
            `selectOptions and value of ${label} cannot be undefined`
          );
        }
        return (
          <CustomSelectInput
            disabled={disabled}
            label={normalLabel}
            id={`${normalLabel}Input`}
            defaultValue={value}
            onChange={onChange}
            selectOptions={selectOptions}
          />
        );
      case 'textarea':
        return (
          <TextAreaField
            disabled={disabled}
            id={`${normalLabel}Input`}
            label={label}
            defaultValue={value}
            onChange={onChange}
            resize="vertical"
            width="100%"
            height="7em"
          />
        );
      case 'checkbox':
        return (
          <Checkbox
            disabled={disabled}
            checked={value === 'true'}
            onChange={(event, checked) =>
              onChange({
                target: { id: `${normalLabel}Input`, value: checked },
              })
            }
            inputProps={{ 'aria-label': 'controlled' }}
          />
        );
    }
  }

  return (
    <div className="Row Between">
      {inputType !== 'textarea' && <p className="Bold InputLabel">{label}</p>}
      {!editMode ? renderValue() : renderInput()}
    </div>
  );
};

export default CustomInputField;
