import { ChangeEvent, useContext, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { IClientOrder } from '../../interfaces';
import EditButtons from '../../components/EditButtons';
import { GlobalContext } from '../../store';
import { alert } from '../../lib/utils';
import { confirm } from '../../lib/utils';
import CustomInputField from '../../components/Order/CustomInputField';

type ContextType = { clientOrder: IClientOrder };

const ClientOrderCancel = () => {
  const { api, context } = useContext(GlobalContext);
  const { clientOrder } = useOutletContext<ContextType>();

  const [cancelReason, setCancelReason] = useState<string>('');
  const navigate = useNavigate();

  const handleClientOrderCancel = async () => {
    if (!(await confirm('Vas a cancelar la orden.'))) {
      return;
    }
    context.startLoading();
    try {
      await api.cancelClientOrder(clientOrder.id, cancelReason);
      navigate('../');
      window.location.reload();
    } catch (error) {
      if (error instanceof Error) {
        alert('error', error.message);
      }
    }
    context.finishLoading();
  };

  return (
    <>
      <div className="FormBorder">
        <h2 className="Row Center">Cancelar Orden</h2>
        <div className="OrderContainer Center">
          <form>
            <CustomInputField
              inputType="select"
              editMode={true}
              value={cancelReason}
              label="Motivo cancelación Pedido"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setCancelReason(e.target.value)
              }
              selectOptions={[
                {
                  value: 'client-cancel-request',
                  label: 'Solicitud del Cliente',
                  disabled: clientOrder.status === 'completed',
                },
                {
                  value: 'client-fail-request',
                  label: 'Solicitud de Falla',
                  disabled: false,
                },
                {
                  value: 'no-stock',
                  label: 'Falta de stock',
                  disabled: clientOrder.status === 'completed' || clientOrder.Orders.slice(-1)[0].status === 'fulfilled',
                },
              ]}
            />
            <EditButtons
              editMode
              onCancel={() => navigate('../')}
              onSave={handleClientOrderCancel}
            />
          </form>
        </div>
      </div>
    </>
  );
};

export default ClientOrderCancel;
