import {
  Datagrid,
  DateField,
  List,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
  TextInput,
} from 'react-admin';
import { AdminPagination } from '../AdminLayout';

const receptionFilters = [
  <ReferenceInput
    source="ClientId"
    reference="clients"
    sort={{ field: 'name', order: 'ASC' }}
    perPage={1000}
    alwaysOn
  >
    <SelectInput optionText="name" />
  </ReferenceInput>,
  <NumberInput label="Id" source="id" alwaysOn />,
  <TextInput label="Platform Code" source="platformCode" />,
  <TextInput label="Document Number" source="documentNumber" />,
];

export const ReceptionList = () => (
  <List
    filters={receptionFilters}
    sort={{ field: 'id', order: 'DESC' }}
    pagination={<AdminPagination />}
  >
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <DateField source="date" />
      <TextField source="documentNumber" />
      <TextField source="status" />
      <ReferenceField source="ClientId" reference="clients">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="LocalId" reference="locals">
        <TextField source="name" />
      </ReferenceField>
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </Datagrid>
  </List>
);
