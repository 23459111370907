export const getProductLocations = (limit: number, offset: number) => `
  query getProductLocations(
    $where: ProductLocations_bool_exp!,
    $sorting: [ProductLocations_order_by!]
  ) {
    ProductLocations(limit: ${limit}, offset: ${offset}, order_by: $sorting, where: {
    _and: [
        { Product: { activated: { _eq: true} }}
        $where,
      ]
    }) {
      id
      onHand
      Location {
        id
        name
      }
      Product {
        id
        name
        sku
        ClientId
      }
      Lot {
        id
        expirationDate
      }
    }
    ProductLocations_aggregate(where: {
      _and: [
        { Product: { activated: { _eq: true } } },
        $where
      ]
    }) {
      aggregate {
        total: count
      }
    }
  }
`;

export const searchProductsWithStock = (
  options: { limit?: number; offset?: number } = {}
) => {
  const { limit = 20, offset = 0 } = options;
  return `
  query SearchProductsWithStock(
    $pointOfSaleId: Int!
    $localId: Int!
    $input: String!
  ) {
    PointOfSaleProducts(
      limit: ${limit},
      offset: ${offset},
      order_by: { Product: { name: asc } },
      where: {
        _and: [
          { PointOfSaleId: { _eq: $pointOfSaleId } }
          { Product: { activated: { _eq: true}, Stocks: { LocalId: { _eq: $localId } } }}
        ],
        _or: [
          {Product: { name: { _ilike: $input } }}
          {Product: { sku: { _ilike: $input } }}
          {Product: { barcode: { _ilike: $input } }}
        ]
      }) {
      Product {
        id
        sku
        name
        Stocks(limit: 1, where: { LocalId: { _eq: $localId } }) {
          onHand
          available
        }
      }
    }
  }
  `;
};

export const searchProducts = (
  options: { limit?: number; offset?: number } = {}
) => {
  const { limit = 20, offset = 0 } = options;
  return `
  query SearchProducts(
    $pointOfSaleId: Int!
    $input: String!
  ) {
    PointOfSaleProducts(
      limit: ${limit},
      offset: ${offset},
      order_by: { Product: { name: asc } },
      where: {
        _and: [
          { PointOfSaleId: { _eq: $pointOfSaleId } }
          { Product: { activated: { _eq: true} }}
        ],
        _or: [
          {Product: { name: { _ilike: $input } }}
          {Product: { sku: { _ilike: $input } }}
        ]
      }) {
      Product {
        id
        sku
        name
      }
    }
  }
  `;
};

export const getProductWithStock = (
  options: { limit?: number; offset?: number } = {}
) => {
  const { limit = 1, offset = 0 } = options;
  return `
  query getProductWithStock(
    $productId: Int!
    $localId: Int!
) {
    Products(
    limit: ${limit}
    offset: ${offset}
    where: {
      _and: [
        { id: { _eq: $productId } }
      ],
    }) {
      id
      sku
      name
      isPack
      isWeighted
      Stocks(limit: 1, where: {LocalId: {_eq: $localId}}) {
        available
      }
    }
  }
  `;
};

export const getCountries = () => `
  query getCountries {
    Countries(order_by: {name: asc}) {
      id
      name
      Regions {
        id
        name
        Communes(order_by: {name: asc}) {
          id
          name
        }
      }
    }
  }
`;

export const getClientLocals = () => `
  query getClientLocals(
    $where: ClientLocals_bool_exp!
  ) {
    ClientLocals(where: $where) {
      id
      ClientId
      LocalId
      Local {
        id
        stockSourceId
        name
      }
    }
  }
`;

export const getInactiveClients = () => `
  query getInactiveClients {
    Clients(
      order_by: {id: asc},
      where: {
        _and: [
          { active: { _eq: false} }
        ]
      }
    ) {
      id
    }
  }
`;

export const getPos = () => `
query getPos(
  $where: POS_bool_exp!
) {
  POS(where: $where) {
    id
    name
    ClientId
    CountryId
    IntegrationId
  }
}
`;

export const getProductsBySku = () => `
query getProductsBySku(
  $where: PointOfSaleProducts_bool_exp!
) {
  PointOfSaleProducts(where: {
    _and: [
        { Product: { activated: { _eq: true} }}
        $where,
      ]
  }) {
    Product {
      id
      name
      sku
      rawSku
      isPack
    }
  }
}
`;

export const getLocalLosses = (limit: number, offset: number) => `
query getLocalLosses(
  $where: Losses_bool_exp!
  $sorting: [Losses_order_by!]
) {
  Losses(limit: ${limit}, offset: ${offset}, where: $where, order_by: $sorting) {
    id
    status
    createdAt
    ProductLocationMove {
      id
      ProductId
      LotId
      ClientId
      quantity
      reason
      Location {
        id
        LocalId
      }
      Product {
        name
        sku 
      }
      Lot {
        expirationDate
      }
    }  
    Withdrawal {
      createdAt
      url
    }
  }
  Losses_aggregate(where: $where) {
    aggregate {
      total: count
    }
  }
}
`;


export const getOrderTypes = (limit: number, offset: number) => `
query getOrderTypes(
  $where: OrderTypes_bool_exp!,
) {
  OrderTypes(limit: ${limit}, offset: ${offset}, where: $where) {
    id
    name
  }
}
`;

export const getTransferClientOrders = (limit: number, offset: number) => `
query getTransferOrders(
  $where: ClientOrders_bool_exp!
  $sorting: [ClientOrders_order_by!]
) {
  ClientOrders(limit: ${limit}, offset: ${offset}, where: $where, order_by: $sorting) {
    id
    date
    code
    status
    shippingType
    Client {
      name
    }
    Orders {
      OrderReceptions {
        Reception {
          id
          status
        }
      }
    }
  }
}
`;

export const getOrders = (limit: number, offset: number) => `
  query getOrders(
    $where: Orders_bool_exp!,
    $sorting: [Orders_order_by!]
  ) {
    Orders(limit: ${limit}, offset: ${offset}, where: $where, order_by: $sorting) {
      id
      status
      internalCode
      shippingType
      incidenceReason
      pickingPriority
      notFoundProducts
      ClientOrderId
      OrderDetails {
        id
        price
        amount
        discount
        ProductId
        PackParentId
      }
      PickingErrors {
        id
        type
      }
      Shipments(order_by: { id: desc })  {
        id
        status
        pickupDate
        CourierId
        labels
        ShipmentLogs(order_by: { id: desc }) {
          id
          status
          createdAt
        }
        Courier {
          id
          name
        }
      }
      ClientOrder {
        id
        code
        customerName
        customerEmail
        note
        status
        date
        shippingType
        shippingCost
        shippingDiscount
        globalDiscount
        platformCode
        invoiceSignedUrl
        customerPhone
        process
        cancelReason
        ClientId
        LocalId
        CommuneId
        PointOfSaleId
        OrderTypeId
        shippingAddress 
        OrderType {
          id
          name
        }
        Commune {
          id
          name
          normalName
        }
        Orders(order_by: { id: desc }) {
          id
          incidenceReason
          status
        }
      }
      Local {
        id
        name
      }
    }
    Orders_aggregate(where: $where) {
      aggregate {
        total:count
      }
    }
  }
`;

export const getClientOrders = (limit: number, offset: number) => `
  query getClientOrders(
    $where: ClientOrders_bool_exp!,
    $sorting: [ClientOrders_order_by!]
  ) {
    ClientOrders(limit: ${limit}, offset: ${offset}, where: $where, order_by: $sorting) {
      id
      code
      customerName
      customerEmail
      note
      status
      date
      shippingType
      shippingCost
      shippingDiscount
      globalDiscount
      platformCode
      invoiceSignedUrl
      customerPhone
      process
      cancelReason
      ClientId
      Client {
        id
        name
        normalName
        prefix
      }
      LocalId
      CommuneId
      PointOfSaleId
      OrderTypeId
      shippingAddress  
      Local {
        id
        name
      }
      OrderType {
        id
        name
      }
      Commune {
        id
        name
        normalName
      }
      Documents {
        id
        url
        number
        type
      }
      Orders(order_by: { id: asc }) {
        id
        status
        internalCode
        shippingType
        incidenceReason
        OrderDetails {
          id
          price
          amount
          discount
          ProductId
          PackParentId
        }
        Shipments(order_by: { id: desc })  {
          id
          status
          pickupDate
        }
        Local {
          id
          name
        }
      }
    }
    ClientOrders_aggregate(where: $where) {
      aggregate {
        total:count
      }
    }
  }
`;
